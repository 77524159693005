import React from "react";

function Page404(){

    return (

        <fregment>

            <h1>404 Page Not Found!</h1>
        </fregment>
    );
}

export default Page404;