import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";

const api_baseurl = process.env.REACT_APP_BASE_URL_API

function StockAnalysis() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [order, setOrder] = useState("ASC");

  const tableRef = useRef(null);
  const params = useParams();
  let vendor_id  = atob(params.vendor_id);

  function parentAlert(demoData) {
    //console.warn(demoData);
    //setDateRange(demoData);
  }

  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.result.sort((a,b) =>
      
      col=='received_qty' || col=='return_to_vendor'
         ?
         a[col].toLowerCase()-b[col].toLowerCase()
         :
         a[col].toLowerCase() > b[col].toLowerCase()? 1: -1
        
      );
      
     // setData(sorted)
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.result.sort((a,b)=>
      col=='received_qty' || col=='return_to_vendor'
          ?
          b[col].toLowerCase() - a[col].toLowerCase()
          :
          a[col].toLowerCase() < b[col].toLowerCase()? 1: -1
       
      );
    // setData(sorted)
      setOrder("ASC")
    }
  }

  useEffect(() => {
    setLoading(true);
    fetch(
      api_baseurl+"stock_analysis?vendor_id=" +
        vendor_id
    ).then((res) => {
      res.json().then((response) => {
        setData(response);
        setLoading(false);
      });
    });
  }, []);

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Stock Analysis</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Inventory Reports</a>
              </li>
              <li className="breadcrumb-item active">Stock Analysis</li>
            </ol>
            <a href={api_baseurl+"stock_analysis?vendor_id="+vendor_id+"&date_range="+"&is_download=1"}> <button className="export-data" ><i className="icon-excel"></i> Export Data</button></a>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        {/* <div className="col date-ic">
                      <label for="selectdate"></label>
                      <DateRangePickerComp alert={parentAlert}></DateRangePickerComp>
                      <div className="calendar-ic">
                        <button><i className="icon-calendar-ic"></i></button>
                      </div>
                    </div> */}
                        <div className="col search-ic col-lg-6">
                          <label for="selectdate"></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  {/* <!-- Table with stripped rows --> */}
                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <div className="table-responsive orderid-select">
                      <table
                        className="table table-striped table-bordered"
                        rel="tableRef"
                      >
                        <thead>
                          <tr>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("category_name")}
                              >
                                Product Category
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("brand_name")}
                              >
                                Brand
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("product_name")}
                              >
                                Product Name
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("supplier_name")}
                              >
                                Vendor
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("received_qty")}
                              >
                                Received Qty (Item)
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("return_to_vendor")}
                              >
                                Returned to Vendor
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.result &&
                            data.result
                              .filter(
                                (item) =>
                                  item.category_name
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.brand_name
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.product_name
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.supplier_name
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.supplier_sku
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.received_qty
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.return_to_vendor
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((item) => (
                                <tr id={item.product_id} key={item.product_id}>
                                  <td>{item.category_name}</td>
                                  <td>{item.brand_name}</td>
                                  <td>{item.product_name}</td>
                                  <td>{item.supplier_name}</td>
                                  <td>{item.received_qty}</td>
                                  <td>{item.return_to_vendor}</td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/*  <!-- End Table with stripped rows --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default StockAnalysis;
