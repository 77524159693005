import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";

const api_baseurl = process.env.REACT_APP_BASE_URL_API;

function EventBased() {
  const [data, setData] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [eventYear, setEventYear] = useState([]);
  const [eventId, setEventId] = useState("");
  const [eventData, setEventData] = useState([]);
  const [allEventData, setAllEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  const [order, setOrder] = useState("ASC");

  const tableRef = useRef(null);

  const params = useParams();
  let vendor_id = atob(params.vendor_id);

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = allEventData.product_based.sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      //setData(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = allEventData.product_based.sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      // setData(sorted);
      setOrder("ASC");
    }
  };

  function parentAlert(demoData) {
    setDateRange(demoData);
    setFinalDateValue(searchDateRange);
  }

  useEffect(() => {
    getAllEventData(eventId);
  }, []);

  function getAllEventData(event_id) {
    setLoading(true);
    fetch(
      api_baseurl +
        "getAllEventData?vendor_id=" +
        vendor_id +
        "&event_id=" +
        event_id
    ).then((res) => {
      res.json().then((response) => {
        setAllEventData(response);
        setLoading(false);
      });
    });
  }

  const event_year = [
    { year: 2024 },
    { year: 2023 },
    { year: 2022 },
    { year: 2021 },
    { year: 2020 },
  ];

  function handleEventYear(e) {
    setEventYear(e.target.value);

    getEventByYear(e.target.value);
    //alert(e.target.value)
  }

  function handleEvent(e) {
    setEventId(e.target.value);

    getEventById(e.target.value);

    getAllEventData(e.target.value); // get event by event id
  }

  function getEventByYear(year) {
    fetch(
      api_baseurl + "eventList?vendor_id=" + vendor_id + "&year=" + year
    ).then((res) => {
      res.json().then((response) => {
        setEventList(response);
      });
    });
  }

  function getEventById(event_id) {
    fetch(
      api_baseurl +
        "getEventById?vendor_id=" +
        vendor_id +
        "&event_id=" +
        event_id
    ).then((res) => {
      res.json().then((response) => {
        setEventData(response);
      });
    });
  }

  console.log("test ", allEventData);

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Loyalty Point</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Loyalty Point</a>
              </li>
              <li className="breadcrumb-item active">Event Based</li>
            </ol>
            <a
              href={
                api_baseurl +
                "getAllEventData?vendor_id=" +
                vendor_id +
                "&date_range=" +
                finalDateValue +
                "&is_download=1"
              }
            >
              {" "}
              <button className="export-data">
                <i className="icon-excel"></i> Export Data
              </button>
            </a>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic col-lg-4">
                          <label for="selectdate">Select Year</label>
                          <select
                            className="form-control"
                            onChange={handleEventYear}
                          >
                            <option>Select</option>

                            {event_year.map((item) => (
                              <option value={item.year}>{item.year}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col date-ic col-lg-4">
                          <label for="selectdate">Select Event</label>
                          <select
                            className="form-control"
                            onChange={handleEvent}
                          >
                            <option>Select</option>
                            {eventList.result &&
                              eventList.result.map((item, index) => (
                                <option value={item.program_event_id}>
                                  {item.event_name}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="col search-ic col-lg-4">
                          <label for="selectdate">Start - End </label>

                          {/* <DateRangePickerComp alert={parentAlert} ></DateRangePickerComp> */}

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Date"
                            value={eventData.result?.date_range}
                            onChange={(e) => setEventDate(e.target.value)}
                          />
                        </div>

                        {/* <div className="col search-ic col-lg-2">
                      <label for="selectdate"></label>
                      <button type="button" className="go-btn btn-event-based" >Filter</button>
                    </div>  */}
                      </div>
                    </form>
                  </div>

                  <section className="section pst-boxes">
                    <div className="row">
                      <div className="container text-center">
                        <div className="row row-cols-1   row-cols-lg-3 g-3 g-lg-3">
                          <div className="col">
                            <div className="cmn-box">
                              <span>Customer Registration </span>
                              <h5>
                                {allEventData.registration_point
                                  ? allEventData.registration_point
                                  : "0"}{" "}
                                Points
                              </h5>
                            </div>
                          </div>
                          <div className="col">
                            <div className="cmn-box">
                              <span>Amount Spent</span>
                              <h5>
                                {allEventData.amount_spent
                                  ? allEventData.amount_spent
                                  : "0"}{" "}
                                Points
                              </h5>
                            </div>
                          </div>
                          <div className="col">
                            <div className="cmn-box">
                              <span>Visit Based</span>
                              <h5>
                                {allEventData.visit_based
                                  ? allEventData.visit_based
                                  : "0"}{" "}
                                Points
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/*  <!-- Table with stripped rows --> */}

                  <div className="table-responsive orderid-select">
                    <h5 style={{ textAlign: "left" }}>Products</h5>
                    <table
                      className="table table-striped table-bordered"
                      rel="tableRef"
                    >
                      <thead>
                        <tr>
                        <th scope="col" style={{ textAlign: "left" }}>
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("product_name")}
                              >
                                Name
                              </a>
                            </th>
                          <th scope="col" style={{ textAlign: "left" }}>
                            <a
                              className="blk dataTable-sorter"
                              href="javascript:void(0);"
                              onClick={()=>sorting("category_name")}
                            >
                              Category
                            </a>
                          </th>
                          <th scope="col" style={{ textAlign: "left" }}>
                            <a
                              className="blk dataTable-sorter"
                              href="javascript:void(0);"
                              onClick={()=>sorting("points_value")}
                            >
                              Points
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allEventData.product_based &&
                          allEventData.product_based.map((item) => (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                {item.product_name}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.category_name}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.points_value}
                              </td>
                            </tr>
                          ))}

                        <tr>
                          <td colSpan={3} style={{ textAlign: "left" }}>
                            <h5>Services</h5>
                          </td>
                        </tr>

                        {allEventData.service_based &&
                          allEventData.service_based.map((item) => (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                {item.service_name}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.category_name}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.points_value}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default EventBased;
