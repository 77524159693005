import React, { useEffect, useState, useRef } from "react";
import Loader from "react-js-loader";

function LoaderJS(){

    return (
        <fregment>
            <Loader  type="spinner-default" bgColor={"#955dff"} size={50} />
            </fregment>
    )
}

export default LoaderJS;