import React from "react";

import { useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-date-range'

import format from 'date-fns/format'
import { addDays } from 'date-fns'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './include/Daterange.css'
import $ from 'jquery';





const DateRangePickerComp = (props) => {


  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ])

  // open close
  const [open, setOpen] = useState(false)
  const [selectRange, setDateRange] = useState('')
  const [addButton, setAddButton] = useState(false)
 



  // get the target element to toggle 
  const refOne = useRef(null)
  props.alert(`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`)

 
  function pickDate(datepicked){
    // alert('checkk');
     setOpen(false)
  //   alert(`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`)
    alert(datepicked)

   }
   
    

   $(document).on('click','.dynamic_btn',function(){

    var datepicked = $("input[name=dateFilter]").val()
     pickDate(datepicked)

   
   });
 

  
  

  useEffect(() => {
   
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
    
    

  }, [])

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if( e.key === "Escape" ) {
      setOpen(false)
     // props.alert(`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`)
    }
  }

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    props.alert(`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`)
     // geteDateData(range)
    if( refOne.current && !refOne.current.contains(e.target) ) {
      
      setOpen(false)
     // props.alert('outside '+`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`)
    // props.alert(`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`)
      
    }
  }

 function geteDateData(aaa){

    console.warn('date range : ',aaa);
 }
  
function setApplyButton(){

      console.log('checkkkked2');
 
    
      //$('.rdrInputRanges').append("<button type='button' class='dynamic_btn' style='color:#fff;background-color:#955dff;width:55px;height:31px;border-radius:8px;margin-top:15px'>Apply</button>") 
    
        $('.rdrInputRanges').append("<button type='button' class='dynamic_btn' style='color:#fff;background-color:#955dff;width:55px;height:31px;border-radius:8px;margin-top:15px'>Apply</button>") 
      
      


}
  return (
    
    <div className="">
      
      <input
        value={`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`}
        readOnly
        className="form-control "
        /* onClick={ () => setOpen(open => !open) } */
        name="dateFilter"
        onChange={(e)=>setDateRange(e.target.value)}
        onClick={() => {
          setOpen(open => !open)
          props.alert(`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`)
         // props.alert(selectRange)
      
         
        }}
        
      />

      <div ref={refOne}>
        {open && 
          <DateRangePicker
            onChange={item => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
            
          />
        }
      </div>

     

    </div>
  )
}
//rdrInputRange
export default DateRangePickerComp