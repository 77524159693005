import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";

const api_baseurl = process.env.REACT_APP_BASE_URL_API;

function GeneralProgram() {
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  const [order, setOrder] = useState("ASC");

  const tableRef = useRef(null);

  const params = useParams();
  let vendor_id = atob(params.vendor_id);

  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.product_data.sort((a,b)=>
          a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      //setData(sorted);
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.product_data.sort((a,b)=>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
     // setData(sorted);
      setOrder("ASC")
    }
  }

  function parentAlert(demoData) {
    setDateRange(demoData);
    setFinalDateValue(searchDateRange);
  }

  useEffect(() => {
    loyaltyGeneralProgram();
  }, []);

  function loyaltyGeneralProgram(datefilter) {
    setLoading(true);
    fetch(
      api_baseurl +
        "loyaltyGeneralProgram?vendor_id=" +
        vendor_id +
        "&date_range=" +
        datefilter
    ).then((res) => {
      res.json().then((response) => {
        setData(response);
        setLoading(false);
      });
    });
  }

  function getCalendarValue() {
    setFinalDateValue(searchDateRange);

    console.log("calendar value:  ", finalDateValue);

    loyaltyGeneralProgram(finalDateValue);
  }

  console.log("new data ", data);

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Loyalty Point</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Loyalty Point</a>
              </li>
              <li className="breadcrumb-item active">General Program</li>
            </ol>

            <a
              href={
                api_baseurl +
                "loyaltyGeneralProgram?vendor_id=" +
                vendor_id +
                "&date_range=" +
                finalDateValue +
                "&is_download=1"
              }
            >
              {" "}
              <button className="export-data">
                <i className="icon-excel"></i> Export Data
              </button>
            </a>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic col-lg-6">
                          <label for="selectdate"></label>
                          <DateRangePickerComp
                            alert={parentAlert}
                          ></DateRangePickerComp>
                          {/* <div className="calendar-ic">
                      <button type="button" onClick={getCalendarValue}><i className="icon-calendar-ic"></i></button>
                      </div> */}
                          <button
                            type="button"
                            className="go-btn"
                            onClick={getCalendarValue}
                          >
                            Go
                          </button>
                        </div>
                        {/* <div className="col search-ic">
                      <label for="selectdate"></label>
                      <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e)=>setQuery(e.target.value)}
                          />
                      <div className="calendar-ic">
                       <button><i className="icon-search"></i></button> 
                      </div>
                    </div> */}
                      </div>
                    </form>
                  </div>

                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <section className="section pst-boxes">
                      <div className="row">
                        <div className="container text-center">
                          <div className="row row-cols-1   row-cols-lg-3 g-3 g-lg-3">
                            <div className="col">
                              <div className="cmn-box">
                                <span>Customer Registration </span>
                                <h5>
                                  {data.registration_point
                                    ? data.registration_point
                                    : "0"}{" "}
                                  Points
                                </h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>Amount Spent</span>
                                <h5>
                                  {data.amount_spent ? data.amount_spent : "0"}{" "}
                                  Points
                                </h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>Visit Based</span>
                                <h5>
                                  {data.visit_based ? data.visit_based : "0"}{" "}
                                  Points
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <div className="table-responsive orderid-select">
                      <h5 style={{ textAlign: "left" }}>Products</h5>
                      <table
                        className="table table-striped table-bordered"
                        rel="tableRef"
                      >
                        
                        <thead>
                          <tr>
                            <th scope="col" style={{ textAlign: "left" }}>
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("product_name")}
                              >
                                Name
                              </a>
                            </th>

                          


                            <th scope="col" style={{ textAlign: "left" }}>
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("category_name")}
                              >
                                Category
                              </a>
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("points_value")}
                              >
                                Points
                              </a>
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("expiration")}
                              >
                                Expiration
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.product_data &&
                            data.product_data.map((item) => (
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  {item.product_name}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {item.category_name}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {item.points_value}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {item.expiration}
                                </td>
                              </tr>
                            ))}

                          <tr>
                            <td colSpan={4} style={{ textAlign: "left" }}>
                              {" "}
                              <h5>Services</h5>{" "}
                            </td>
                          </tr>

                          {data.service_data &&
                            data.service_data.map((item) => (
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  {item.service_name}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {item.category_name}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {item.points_value}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {item.expiration}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default GeneralProgram;
