import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";
const api_baseurl = process.env.REACT_APP_BASE_URL_API

function EmployeeSalesSummary() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  const [order, setOrder] = useState("ASC");

  const tableRef = useRef(null);

  const params = useParams();
  let vendor_id  = atob(params.vendor_id);

  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.result.sort((a,b) =>
      
         col=='no_of_customer' || col=='no_of_service' || col=='service_sales' || col=='no_of_product' || col=='product_sales' || col=='card_tip' || col=='cash_tip' || col=='sales_plus_tip'
         ?
         a[col].toLowerCase()-b[col].toLowerCase()
         :
         a[col].toLowerCase() > b[col].toLowerCase()? 1: -1
        
      );
      
     // setData(sorted)
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.result.sort((a,b)=>
      col=='no_of_customer' || col=='no_of_service' || col=='service_sales' || col=='no_of_product' || col=='product_sales' || col=='card_tip' || col=='cash_tip' || col=='sales_plus_tip'
          ?
          b[col].toLowerCase() - a[col].toLowerCase()
          :
          a[col].toLowerCase() < b[col].toLowerCase()? 1: -1
       
      );
    // setData(sorted)
      setOrder("ASC")
    }
  }


  function parentAlert(demoData) {
    setDateRange(demoData);
    setFinalDateValue(searchDateRange);
  }

  useEffect(() => {
    getCalendarValue();

    loadData(finalDateValue);
  }, []);

  function loadData(datefilter) {
    setLoading(true);
    fetch(
      api_baseurl+"employee_sales_summary?vendor_id=" +
        vendor_id +
        "&date_range=" +
        datefilter
    ).then((res) => {
      res.json().then((response) => {
        setData(response);
        setLoading(false);
      });
    });
  }

  function getCalendarValue() {
    setFinalDateValue(searchDateRange);
    // alert(finalDateValue)
    loadData(finalDateValue);
  }

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Sales Summary</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Employee Reports</a>
              </li>
              <li className="breadcrumb-item active">Sales Summary</li>
            </ol>
            <a href={api_baseurl+"employee_sales_summary?vendor_id="+vendor_id+"&date_range="+finalDateValue+"&is_download=1"}> <button className="export-data" ><i className="icon-excel"></i> Export Data</button></a>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic">
                          <label for="selectdate"></label>
                          <DateRangePickerComp
                            alert={parentAlert}
                          ></DateRangePickerComp>
                          {/* <div className="calendar-ic">
                      <button type="button" onClick={getCalendarValue}><i className="icon-calendar-ic"></i></button>
                      </div> */}
                          <button
                            type="button"
                            className="go-btn"
                            onClick={getCalendarValue}
                          >
                            Go
                          </button>
                        </div>
                        <div className="col search-ic">
                          <label for="selectdate"></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  {loading ? (
                    ""
                  ) : (
                    <section className="section pst-boxes">
                      <div className="row">
                        <div className="container text-center">
                          <div className="row row-cols-1   row-cols-lg-3 g-3 g-lg-3">
                            <div className="col">
                              <div className="cmn-box">
                                <span>#Of Customers </span>
                                <h5>
                                  {data.number_of_customer
                                    ? data.number_of_customer
                                    : "0"}
                                </h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>#Of Services</span>
                                <h5>
                                  {data.number_of_service
                                    ? data.number_of_service
                                    : "0"}
                                </h5>
                              </div>
                            </div>

                            <div className="col">
                              <div className="cmn-box">
                                <span>#Of Products</span>
                                <h5>
                                  {data.number_of_product
                                    ? data.number_of_product
                                    : "0"}
                                </h5>
                              </div>
                            </div>

                            <div className="col">
                              <div className="cmn-box">
                                <span>Services</span>
                                <h5>
                                  $
                                  {data.service_amount
                                    ? parseFloat(data.service_amount).toFixed(2)
                                    : "0.00"}
                                </h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>Products</span>
                                <h5>
                                  $
                                  {data.product_amount
                                    ? parseFloat(data.product_amount).toFixed(2)
                                    : "0.00"}
                                </h5>
                              </div>
                            </div>

                            <div className="col">
                              <div className="cmn-box">
                                <span>Card Tips</span>
                                <h5>
                                  ${data.card_tips ? parseFloat(data.card_tips).toFixed(2) : "0.00"}
                                </h5>
                              </div>
                            </div>

                            <div className="col">
                              <div className="cmn-box">
                                <span>Cash Tips</span>
                                <h5>
                                  ${data.cash_tips ? parseFloat(data.cash_tips).toFixed(2) : "0.00"}
                                </h5>
                              </div>
                            </div>

                            <div className="col">
                              <div className="cmn-box">
                                <span>Total Sales & Tips</span>
                                <h5>
                                  $
                                  {data.total_sales_and_tips
                                    ? parseFloat(data.total_sales_and_tips).toFixed(2)
                                    : "0.00"}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {/*  <!-- Table with stripped rows --> */}
                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <div className="table-responsive orderid-select">
                      <table
                        className="table table-striped table-bordered"
                        rel="tableRef"
                      >
                        <thead>
                          <tr>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("firstname")}
                              >
                                First Name
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("lastname")}
                              >
                                Last Name
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("no_of_customer")}
                              >
                                # Of Customers
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("no_of_service")}
                              >
                                # Of Services
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("service_sales")}
                              >
                                Service Amount
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("no_of_product")}
                              >
                               # of Product
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("product_sales")}
                              >
                                Product Amount
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("card_tip")}
                              >
                                Card Tips
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("cash_tip")}
                              >
                                Cash Tips
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("sales_plus_tip")}
                              >
                                Total Sales & Tips
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.result &&
                            data.result
                              .filter(
                                (item) =>
                                  item.firstname
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.lastname
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.no_of_customer
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.no_of_service
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.service_sales
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                    item.no_of_product
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.product_sales
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.card_tip
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.cash_tip
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.sales_plus_tip
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.total_cost
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((item) => (
                                <tr id={item.stylist_id} key={item.stylist_id}>
                                  <td>{item.firstname}</td>
                                  <td>{item.lastname}</td>
                                  <td>{item.no_of_customer}</td>
                                  <td>{item.no_of_service}</td>
                                  <td style={{ textAlign: "right" }}>
                                    ${parseFloat(item.service_sales).toFixed(2)}
                                  </td>
                                  <td>
                                    {item.no_of_product}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    ${parseFloat(item.product_sales).toFixed(2)}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    ${parseFloat(item.card_tip).toFixed(2)}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    ${parseFloat(item.cash_tip).toFixed(2)}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    ${parseFloat(item.sales_plus_tip).toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* <!-- End Table with stripped rows --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default EmployeeSalesSummary;
