import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useParams,NavLink } from "react-router-dom";
import Loader from "./include/Loader";
import { ExceptionMap } from "antd/es/result";

import Alert from 'react-bootstrap/Alert';

const api_baseurl = process.env.REACT_APP_BASE_URL_API




function TransactionDetailRefund() {
 

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [message, setMessage] = useState("");
  //const [searchDateRange, setDateRange] = useState("");
  const [show, setShow] = useState(true);

  const params = useParams();
  console.log(params);
  let vendor_id  = atob(params.vendor_id);
  let order_id  = atob(params.order_id);

  const tableRef = useRef(null);
 
  

  const loadData = () =>{
    fetch(api_baseurl+"getRefundDetail?vendor_id="+vendor_id+"&order_id="+order_id).then(
      (res) => {
        res.json().then((response) => {
          setData(response);
          setLoading(false)
        });
      }
    );
  }


  const Print = () =>{     
    //console.log('print');  
    let printContents = document.getElementById('tableDiv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   document.body.innerHTML = originalContents; 
  }

  const sendInvoice = () =>{
    setEmailLoading(true)
    fetch(api_baseurl+"sendRefundInvoice?vendor_id="+vendor_id+"&order_id="+order_id+"&is_refund=1").then(
      (res) => {
        res.json().then((response) => {
          //setData(response); 
          setEmailLoading(false)
          console.log("send invoice response: ",response);
         
          setMessage(response.message)
        });
      }
    );
  }

  useEffect(() => {
      setLoading(true)
      loadData()
  }, []);
  


 
  const order_number = data?.orderInfo?.order_number;
  const order_date = data?.orderInfo?.order_date;
  const order_type = data?.orderInfo?.order_type;
  const order_status = data?.orderInfo?.status;
  const total_refund_amount = data?.orderInfo?.total_refund_amount;
  const subtotal = parseFloat(data?.orderInfo?.sub_total).toFixed(2);

  const original_order = data?.main_order_number?.order_number;



  const customerInfo = data?.customerInfo;

  /* const customer_name = data?.customerInfo[0].firstname+' '+data?.customerInfo[0]?.lastname;
  const customer_email = data?.customerInfo[0].email;
  const customer_phone = data?.customerInfo[0].mobile_phone; */

  
  const tax_amount = parseFloat(data?.paymentInfo?.tax_amount).toFixed(2);
  const tip_amount = parseFloat(data?.paymentInfo?.tip_amount).toFixed(2);
  const cash_amount = parseFloat(data?.paymentInfo?.cash_amount).toFixed(2);
  const credit_card_amount = parseFloat(data?.paymentInfo?.credit_card_amount).toFixed(2);
  const change_due = parseFloat(data?.paymentInfo?.change_due).toFixed(2);
  const discount_amount = parseFloat(data?.paymentInfo?.discount_amount).toFixed(2);
  const cash_tip = parseFloat(data?.paymentInfo?.cash_tip).toFixed(2);
  const credit_tip = parseFloat(data?.paymentInfo?.credit_tip).toFixed(2);
  const iou_amount = parseFloat(data?.paymentInfo?.iou_amount).toFixed(2);
  const gift_certificate_amount = parseFloat(data?.paymentInfo?.gift_certificate_amount).toFixed(2);
 
  const gift_card_amount = parseFloat(data?.paymentInfo?.gift_card_amount).toFixed(2);
  const refund_amount = parseFloat(data?.paymentInfo?.refund_amount).toFixed(2);

  const productInfo = data?.productInfo;
  const appointmentInfo = data?.appointmentData;
  const giftCardInfo = data?.gift_card_data;
  const giftCertificateInfo = data?.gift_certificate_data;
  const refundData = data?.refundData;
  const reward_amount = parseFloat(data?.refundData?.orderInfo.reward_amount).toFixed(2);


  const cash_amount_paid = parseFloat(cash_amount);

  //const change_due_final = parseFloat(change_due)-parseFloat(cash_tip);
  const change_due_final = parseFloat(change_due);
   

  const grand_total = (parseFloat(subtotal)+parseFloat(tax_amount)+parseFloat(tip_amount)-parseFloat(discount_amount));



  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>
      
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Transaction Detail </h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Sales Reports</a>
              </li>
              <li className="breadcrumb-item "><NavLink to={"/transaction-list/"+btoa(vendor_id)}>Transaction</NavLink></li>
              <li className="breadcrumb-item active">Transaction Detail</li>
            </ol>
            {/* <DownloadTableExcel  filename="Transaction Detail" sheet="Transaction Detail"  currentTableRef={tableRef.current}>
      <button className="export-data"><i className="icon-excel"></i> Export Data</button>
      </DownloadTableExcel> */}

<button style={{marginRight:"115px"}} className="export-data" id="email_transaction" onClick={sendInvoice}><i className="bi bi-envelope " ></i> Email Receipt</button>
                          <button className="export-data" id="print_transaction" onClick={Print}><i className="bi bi-printer-fill" ></i> Print</button>


          </nav>
        </div>
     
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic" style={{textAlign:"left"}}>
                          <label for="" style={{fontSize:"24px",fontWeight:"700"}}>Refund Order #: {order_number}</label>
                         
                       
                          
                         
                        </div>
                        {emailLoading?<span >Loading...</span>:<span style={{color:"green"}}>{message}</span>}
                      
                       {/*  <div className="col search-ic" style={{textAlign:"right"}}>
                          <label for="selectdate" >
                          <button className="" id="email_transaction" onClick={sendInvoice}><i className="bi bi-envelope " ></i> Email Receipt</button>
                          <button className="" id="print_transaction" onClick={Print}><i className="bi bi-printer-fill" ></i> Print</button>
                         
                          </label>
                          
        
      
      
                        </div> */}
                      </div>
                    </form>
                  </div>
                  
                   {loading?<Loader></Loader>:
                  <div className="trans-bg">
                      <div className="row">  
                      
                        <div className="col-sm-12 col-md-6 col-lg-6 m-bttom">
                            <h6>Customer Information</h6>
                            <ul>
                              <li><span class="min-width">Name:</span>{customerInfo && customerInfo.map((item)=>(item.firstname+' '+item.lastname))}</li>
                              <li><span class="min-width">Email:</span>{customerInfo && customerInfo.map((item)=>(item.email))}	</li>
                              <li><span class="min-width">Mobile:</span>{customerInfo && customerInfo.map((item)=>(item.mobile_phone))}	</li>
                            </ul>
                        </div>
                    
                        <div className="col-sm-12 col-md-6 col-lg-6 m-bttom">
                            <h6>Order Information</h6>
                            <ul>
                              <li><span class="min-width">Order Number:</span>{order_number}</li>
                              <li><span class="min-width">Original Order:</span>{original_order}</li>
                              <li><span class="min-width">Order Date:</span>{order_date}</li>
                              <li><span class="min-width">Order Type:</span>{order_type}</li>
                              <li><span class="min-width">Order Status:</span>{order_status}</li>
                            </ul>
                        </div>


                     

                     
                      
                        <div className="col-sm-12 col-md-6 col-lg-6 m-bttom">
                            <h6>Refund Method</h6>
                            <ul>
                              <li><span class="min-width">Cash:</span> <span class="align-right">${isNaN(parseFloat(cash_amount_paid))?'0.00':parseFloat(cash_amount_paid).toFixed(2)}</span></li>
                              <li><span class="min-width">Card:</span> <span class="align-right">${isNaN(parseFloat(credit_card_amount))?'0.00':parseFloat(credit_card_amount).toFixed(2)}</span></li>
                              <li><span class="min-width">Gift Card:</span> <span class="align-right">${gift_card_amount}</span></li>
                              <li><span class="min-width">Gift Certificate:</span> <span class="align-right">${gift_certificate_amount}</span></li>
                              <li><span class="min-width">Reward:</span> <span class="align-right">${isNaN(reward_amount)?'0.00':reward_amount}</span></li>
                             
                            </ul>
                        </div>
                      </div>

                </div>
                }

            {loading?<Loader></Loader>:
                  <div className="table-responsive orderid-select" id="tableDiv" style={{textAlign:"left",backgroundColor:"#DBE6F1"}}>
                   
                   

                    {productInfo && productInfo?.length > 0 ?
                    <div className="table-responsive orderid-select" style={{textAlign:"left",backgroundColor:"#FFF"}}>
                    <table className="table " ref={tableRef} style={{marginTop:"20px"}}>
                      <thead>
                        <tr style={{backgroundColor:"#eee"}}>
                          <th scope="col" colSpan="5">
                            <a
                              className="blk"
                              href="javascript:void(0);"
                            >
                              Product Detail
                            </a>
                          </th>
                          
                          
                        </tr>

                        <tr scope="col">

                        <th scope="col">
                            <a
                              className="blk"
                              href="javascript:void(0);"
                            >
                             Product Name
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk "
                              href="javascript:void(0);"
                            >
                              Unit Price
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk"
                              href="javascript:void(0);"
                            >
                             Quantity
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk"
                              href="javascript:void(0);"
                            >
                             Total
                            </a>
                          </th>

                          <th scope="col">
                            <a
                              className="blk"
                              href="javascript:void(0);"
                            >
                             Refund Reason
                            </a>
                          </th>


                        </tr>
                      </thead>
                      <tbody>
                      {productInfo &&
                          productInfo.map((item) => (
                            <tr>
                              <td>{item.name}</td>
                              <td>${item.unitprice}</td>
                              <td>{item.quant}</td>
                              <td>-${parseFloat(item.total).toFixed(2)}</td>
                              <td>{item.reason_type}</td>
                             
                            </tr>
                          ))}
                       
                      </tbody>
                    </table>
                    </div>

                 :'' }


                  </div>
                  
                    }



 {appointmentInfo && appointmentInfo?.length > 0 ?

    <div className="table-responsive orderid-select" style={{textAlign:"left",backgroundColor:"#FFF"}}>
                    <table className="table " ref={tableRef} style={{lineHeight:"0.8",marginTop:"20px"}}>
                      <thead>
                        <tr style={{backgroundColor:"#eee"}}>
                          <th scope="col" colSpan="4">
                            <a
                              className="blk"
                              href="javascript:void(0);"
                            >
                              Appointment Detail
                            </a>
                          </th>
                          
                          
                        </tr>

                        <tr scope="col">

                        <th scope="col">
                            <a
                              className="blk"
                              href="javascript:void(0);"
                            >
                             Service Name
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk "
                              href="javascript:void(0);"
                            >
                              Unit Price
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk"
                              href="javascript:void(0);"
                            >
                             Total
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk"
                              href="javascript:void(0);"
                            >
                             Refund Reason
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {appointmentInfo &&
                          appointmentInfo.map((item) => (
                            <tr>
                              <td>{item.service_name}</td>
                              <td>${item.unitprice}</td>
                              <td>-${parseFloat(item.total).toFixed(2)}</td>
                              <td>{item.reason_type}</td>
                             
                            </tr>
                          ))}
                       
                      </tbody>
                    </table>
                    </div>

:''}
                 
                </div>


                
               
              </div>
              
            </div>
            
          </div>
          
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default TransactionDetailRefund;
