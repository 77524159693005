import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";

const api_baseurl = process.env.REACT_APP_BASE_URL_API

function CustomerAppointment() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  const [order, setOrder] = useState("ASC");

  const tableRef = useRef(null);
  const params = useParams();
  let vendor_id  = atob(params.vendor_id);


  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.result.sort((a,b) =>
      
         col=='scheduled_appts' || col=='completed_appts' || col=='rescheduled_appts' || col=='cancelled_appts' || col=='no_shows_appts'
         ?
         a[col].toLowerCase()-b[col].toLowerCase()
         :
         a[col].toLowerCase() > b[col].toLowerCase()? 1: -1
        
      );
      
     // setData(sorted)
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.result.sort((a,b)=>
      col=='scheduled_appts' || col=='completed_appts' || col=='rescheduled_appts' || col=='cancelled_appts' || col=='no_shows_appts'
          ?
          b[col].toLowerCase() - a[col].toLowerCase()
          :
          a[col].toLowerCase() < b[col].toLowerCase()? 1: -1
       
      );
    // setData(sorted)
      setOrder("ASC")
    }
  }

  function parentAlert(demoData) {
    setDateRange(demoData);
    setFinalDateValue(searchDateRange);
  }

  useEffect(() => {
    getCalendarValue();
    loadData(finalDateValue);
  }, []);

  function loadData(datefilter) {
    setLoading(true);
    fetch(
      api_baseurl+"customer_appointment?vendor_id=" +
        vendor_id +
        "&date_range=" +
        datefilter
    ).then((res) => {
      res.json().then((response) => {
        setData(response);
        setLoading(false);
      });
    });
  }

  function getCalendarValue() {
    setFinalDateValue(searchDateRange);
    loadData(finalDateValue);
  }

 

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Customer Appointment</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Customer Reports</a>
              </li>
              <li className="breadcrumb-item active">Customer Appointment</li>
            </ol>
            <a href={api_baseurl+"customer_appointment?vendor_id="+vendor_id+"&date_range="+finalDateValue+"&is_download=1"}> <button className="export-data" ><i className="icon-excel"></i> Export Data</button></a>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic">
                          <label for="selectdate"></label>
                          <DateRangePickerComp
                            alert={parentAlert}
                          ></DateRangePickerComp>
                          {/* <div className="calendar-ic">
                      <button type="button" onClick={getCalendarValue}><i className="icon-calendar-ic"></i></button>
                      </div> */}
                          <button
                            type="button"
                            className="go-btn"
                            onClick={getCalendarValue}
                          >
                            Go
                          </button>
                        </div>
                        <div className="col search-ic">
                          <label for="selectdate"></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  {loading ? (
                    ""
                  ) : (
                    <section className="section pst-boxes">
                      <div className="row">
                        <div className="container text-center">
                          <div className="row row-cols-1   row-cols-lg-4 g-3 g-lg-3">
                            <div className="col">
                              <div className="cmn-box">
                                <span>Scheduled </span>
                                <h5>{data.scheduled}</h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>Completed</span>
                                <h5>{data.completed}</h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>Rescheduled</span>
                                <h5>{data.rescheduled}</h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>Cancelled</span>
                                <h5>{data.cancelled}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {/*  <!-- Table with stripped rows --> */}
                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <div className="table-responsive orderid-select">
                      <table
                        className="table table-striped table-bordered"
                        rel="tableRef"
                      >
                        <thead>
                          <tr>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("firstname")}
                              >
                                First Name
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("lastname")}
                              >
                                Last Name
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("phone")}
                              >
                                Phone Number
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("email")}
                              >
                                Email
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("scheduled_appts")}
                              >
                                Scheduled
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("completed_appts")}
                              >
                                Completed
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("rescheduled_appts")}
                              >
                                Rescheduled
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("cancelled_appts")}
                              >
                                Cancelled
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("no_shows_appts")}
                              >
                                No Shows
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.result &&
                            data.result
                              .filter(
                                (item) =>
                                  item.firstname
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.lastname
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.phone
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.email
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.scheduled_appts
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.completed_appts
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.rescheduled_appts
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.cancelled_appts
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.no_shows_appts
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((item,index) => (
                                <tr
                                  id={index}
                                  key={index}
                                >
                                  <td>{item.firstname}</td>
                                  <td>{item.lastname}</td>
                                  <td>{item.phone}</td>
                                  <td>{item.email}</td>
                                  <td>{item.scheduled_appts}</td>
                                  <td>{item.completed_appts}</td>
                                  <td>{item.rescheduled_appts?item.rescheduled_appts:'0'}</td>
                                  <td>{item.cancelled_appts}</td>
                                  <td>{item.no_shows_appts}</td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* <!-- End Table with stripped rows --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default CustomerAppointment;
