import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";

const api_baseurl = process.env.REACT_APP_BASE_URL_API

function CustomerEgiftCard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  const [order, setOrder] = useState("ASC");

  const tableRef = useRef(null);

  const params = useParams();
  let vendor_id  = atob(params.vendor_id);

  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.result.sort((a,b) =>
      
         col=='card_number' || col=='issue_amount' || col=='amount_used' || col=='balance'
         ?
         a[col].toLowerCase()-b[col].toLowerCase()
         :
         a[col].toLowerCase() > b[col].toLowerCase()? 1: -1
        
      );
      
     // setData(sorted)
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.result.sort((a,b)=>
      col=='card_number' || col=='issue_amount' || col=='amount_used' || col=='balance'
          ?
          b[col].toLowerCase() - a[col].toLowerCase()
          :
          a[col].toLowerCase() < b[col].toLowerCase()? 1: -1
       
      );
    // setData(sorted)
      setOrder("ASC")
    }
  }

  function parentAlert(demoData) {
    setDateRange(demoData);
    setFinalDateValue(searchDateRange);
  }

  useEffect(() => {
    getCalendarValue();

    loadData(finalDateValue);
  }, []);

  function loadData(datefilter) {
    setLoading(true);
    fetch(
      api_baseurl+"customer_egift_card?vendor_id=" +
        vendor_id +
        "&date_range=" +
        datefilter
    ).then((res) => {
      res.json().then((response) => {
        setData(response);
        setLoading(false);
      });
    });
  }

  function getCalendarValue() {
    setFinalDateValue(searchDateRange);
    // alert(finalDateValue)
    loadData(finalDateValue);
  }

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>E-Gift Card</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Customer Reports</a>
              </li>
              <li className="breadcrumb-item active">E-Gift Card</li>
            </ol>
            <a href={api_baseurl+"customer_egift_card?vendor_id="+vendor_id+"&date_range="+finalDateValue+"&is_download=1"}> <button className="export-data" ><i className="icon-excel"></i> Export Data</button></a>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic">
                          <label for="selectdate"></label>
                          <DateRangePickerComp
                            alert={parentAlert}
                          ></DateRangePickerComp>
                          {/* <div className="calendar-ic">
                      <button type="button" onClick={getCalendarValue}><i className="icon-calendar-ic"></i></button>
                      </div> */}
                          <button
                            type="button"
                            className="go-btn"
                            onClick={getCalendarValue}
                          >
                            Go
                          </button>
                        </div>
                        <div className="col search-ic">
                          <label for="selectdate"></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {loading ? (
                    ""
                  ) : (
                    <section className="section pst-boxes">
                      <div className="row">
                        <div className="col-lg-12">
                          <ul>
                            <li>
                              <span>Total Issued </span>
                              <h5>
                                $
                                {data.total_issued ? parseFloat(data.total_issued).toFixed(2) : "0.00"}
                              </h5>
                            </li>
                            <li>
                              <span>Total Used</span>
                              <h5>
                                ${data.used_amount ? parseFloat(data.used_amount).toFixed(2) : "0.00"}
                              </h5>
                            </li>
                            <li>
                              <span>Balance</span>
                              <h5>${data.balance ? parseFloat(data.balance).toFixed(2) : "0.00"}</h5>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>
                  )}

                  {/*  <!-- Table with stripped rows --> */}
                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <div className="table-responsive orderid-select">
                      <table
                        class="table table-striped table-bordered"
                        rel="tableRef"
                      >
                        <thead>
                          <tr>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("firstname")}
                              >
                                First Name{" "}
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("lastname")}
                              >
                                Last Name
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("phone")}
                              >
                                Phone
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("card_number")}
                              >
                                Card Number
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("issue_date")}
                              >
                                Issued Date
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("issue_amount")}
                              >
                                Issued Amount
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("date_used")}
                              >
                                 Date Used
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("amount_used")}
                              >
                                 Amount Used
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("balance")}
                              >
                                Balance
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.result &&
                            data.result
                              .filter(
                                (item) =>
                                  item.firstname
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.lastname
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.phone
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.card_number
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.issue_date
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.issue_amount
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.date_used
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.amount_used
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.balance
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((item,index) => (
                                <tr
                                  id={index}
                                  key={index}
                                >
                                  <td>{item.firstname}</td>
                                  <td>{item.lastname}</td>
                                  <td>{item.phone}</td>
                                  <td>{item.card_number}</td>
                                  <td>{item.issue_date}</td>
                                  <td style={{ textAlign: "right" }}>
                                    $
                                    {item.issue_amount
                                      ? parseFloat(item.issue_amount).toFixed(2)
                                      : "0.00"}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {item.date_used
                                      ? item.date_used
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    
                                    {
                                 
                                    item.amount_used!=''
                                      ? '$'+parseFloat(item.amount_used).toFixed(2)
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    ${item.balance ? parseFloat(item.balance).toFixed(2) : "0.00"}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* <!-- End Table with stripped rows --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default CustomerEgiftCard;
