import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";

const api_baseurl = process.env.REACT_APP_BASE_URL_API

function CustomerList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  const [order, setOrder] = useState("ASC");

  const tableRef = useRef(null);

  const params = useParams();
  let vendor_id  = atob(params.vendor_id);

  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.result.sort((a,b)=>
          a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      //setData(sorted);
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.result.sort((a,b)=>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
     // setData(sorted);
      setOrder("ASC")
    }
  }

  

  function parentAlert(demoData) {
    setDateRange(demoData);
    setFinalDateValue(searchDateRange);
  }

  useEffect(() => {
    getCalendarValue();

    loadData(finalDateValue);
  }, []);

  function loadData(datefilter) {
    setLoading(true);
    fetch(
      api_baseurl+"customer_list?vendor_id=" +
        vendor_id
    ).then((res) => {
      res.json().then((response) => {
        setData(response);
        setLoading(false);
      });
    });
  }

  function getCalendarValue() {
    setFinalDateValue(searchDateRange);
    // alert(finalDateValue)
    loadData(finalDateValue);
  }

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Customer List</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Customer Reports</a>
              </li>
              <li className="breadcrumb-item active">Customer List</li>
            </ol>
            <a href={api_baseurl+"customer_list?vendor_id="+vendor_id+"&date_range="+finalDateValue+"&is_download=1"}> <button className="export-data" ><i className="icon-excel"></i> Export Data</button></a>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        {/* <div className="col date-ic">
                      <label for="selectdate"></label>
                      <DateRangePickerComp alert={parentAlert}></DateRangePickerComp>
                      <div className="calendar-ic">
                        <button><i className="icon-calendar-ic"></i></button>
                      </div>
                    </div> */}
                        <div className="col search-ic col-lg-6">
                          <label for="selectdate"></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  {/*  <!-- Table with stripped rows --> */}
                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <div className="table-responsive orderid-select">
                      <table
                        class="table table-striped table-bordered"
                        rel="tableRef"
                      >
                        <thead>
                          <tr>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("firstname")}
                              >
                                First Name{" "}
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("lastname")}
                              >
                                Last Name
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("email")}
                              >
                                Email
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("birthday")}
                              >
                                Birthday
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("gender")}
                              >
                                Gender
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("city")}
                              >
                                City
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("last_visit")}
                              >
                                Last Visit
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("customer_since")}
                              >
                                Customer Since
                              </a>
                            </th>

                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("reward_point")}
                              >
                                Reward Balance Point
                              </a>
                            </th>


                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("reward_amount")}
                              >
                                Reward Balance Amount
                              </a>
                            </th>

                           

                          </tr>
                        </thead>
                        <tbody>
                          {data.result &&
                            data.result
                              .filter(
                                (item,index) =>

                                item.firstname
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||

                                item.lastname
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||

                                item.email
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||

                                item.birthday
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||

                                item.gender
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||

                                item.city
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||

                                item.last_visit
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||

                                item.customer_since
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||

                                item.reward_point
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||

                                item.reward_amount
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase())
                                
                                 
                                
                              )
                              .map((item, index) => (
                                <tr id={index} key={index}>
                                  <td>{item.firstname}</td>
                                  <td>{item.lastname}</td>
                                  <td>{item.email}</td>
                                  <td>{item.birthday}</td>
                                  <td>{item.gender}</td>
                                  <td>{item.city}</td>
                                  <td>{item.last_visit}</td>
                                  <td>{item.customer_since}</td>
                                  <td>{item.reward_point}</td>
                                  <td>${item.reward_amount}</td>
                                 
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* <!-- End Table with stripped rows --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default CustomerList;
