import React, {useEffect, useState, useRef} from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";
import Position from "rsuite/esm/Overlay/Position";

const api_baseurl = process.env.REACT_APP_BASE_URL_API

function SalesAndPaymentSummary(){

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  const tableRef = useRef(null);



  const params = useParams();
  let vendor_id  = atob(params.vendor_id);

  function parentAlert(demoData){
  
    setDateRange(demoData)
    setFinalDateValue(searchDateRange)
  }

  

   useEffect(() => {
    getCalendarValue()
    loadData(finalDateValue)
  }, []); 

  function loadData(datefilter){
 
    setLoading(true)
    
    fetch(api_baseurl+"salesAndPaymentSummary?vendor_id="+vendor_id+"&date_range="+datefilter).then(
      (res) => {
        res.json().then((response) => {
          setData(response);
          setLoading(false)
        });
      }
    );
   }

   function getCalendarValue(){

    setFinalDateValue(searchDateRange)

    //console.log('calendar value:  ',finalDateValue)

  
    loadData(finalDateValue)

  }


  ///console.log('sales and payment data: ',data);

  let transaction_count = data?.summary?.transaction_count;
  let gross_sales = parseFloat(data?.summary?.gross_sales).toFixed(2);
  let net_sales = parseFloat(data?.summary?.net_sales).toFixed(2);

  let service_count = data?.sales_cost?.service?.count;
  let service_total = parseFloat(data?.sales_cost?.service?.cost).toFixed(2);
  let product_count = data?.sales_cost?.product?.count;
  let product_total = parseFloat(data?.sales_cost?.product?.cost).toFixed(2);
  let gift_card_count = data?.sales_cost?.gift_card?.count;
  let gift_card_total = parseFloat(data?.sales_cost?.gift_card?.cost).toFixed(2);

  let sales_cash = parseFloat(data?.sales_combine?.cash).toFixed(2);
  let sales_debit = parseFloat(data?.sales_combine?.debit).toFixed(2);
  let sales_credit = parseFloat(data?.sales_combine?.credit).toFixed(2);
  let sales_gift_card = parseFloat(data?.sales_combine?.gift_card).toFixed(2);
  let sales_discount = parseFloat(data?.sales_combine?.discount).toFixed(2);
  let reward_amount = parseFloat(data?.sales_combine?.reward_amount).toFixed(2);
  //let sales_tax_refund = parseFloat(data?.sales_combine?.tax_refund).toFixed(2);
  let sales_gift_certificate = data?.sales_combine?.credit_certificate;
  let sales_total = parseFloat(data?.sales_combine?.total).toFixed(2);

  
  let refund_cash = parseFloat(data?.refund_combine?.cash).toFixed(2);
  let refund_debit = parseFloat(data?.refund_combine?.debit).toFixed(2);
  let refund_credit = parseFloat(data?.refund_combine?.credit).toFixed(2);
  let refund_gift_card = parseFloat(data?.refund_combine?.gift_card).toFixed(2);
  let refund_discount = parseFloat(data?.refund_combine?.discount).toFixed(2);
  let refund_gift_certificate = parseFloat(data?.refund_combine?.gift_certificate).toFixed(2);
  let refund_total = parseFloat(data?.refund_combine?.total).toFixed(2);

  let discount_discount = parseFloat(data?.discount_combine?.total).toFixed(2);

  let tax_info = data?.tax;
  let tax_refund = data.tax_refund;

  let tips = data?.tips;
  let deposit = data?.deposit;
  let cash_discount = data?.cash_discount?.total_cash_charge;

  let grand_total = data?.grand_total;

  //const total_cash_charge = parseFloat(data?.orderInfo?.total_cash_charge).toFixed(2);



// {Array.from(set).map(element => {


 console.log('cash_discount --- : ',cash_discount);

let product_count2=''
let service_count2=''
let gift_card_count2=''

if(product_count<0){
  product_count2 = 0;
}else{
  product_count2 = product_count
}

if(service_count<0){
  service_count2 = 0;
}else{
  service_count2 = service_count
}



if(gift_card_count<0){
  gift_card_count2 = 0;
}else{
  gift_card_count2 = gift_card_count
}


let service_total2 = ''

if(service_total<0){
  service_total2 = 0
}else{
  service_total2 = service_total
}

//product_total

let product_total2 = ''

if(product_total<0){
  product_total2 = 0
}else{
  product_total2 = product_total
}

//gift_card_total
let gift_card_total2 = ''

if(gift_card_total<0){
  gift_card_total2 = 0
}else{
  gift_card_total2 = gift_card_total
}


let sales_cash2 = ''

if(sales_cash<0){
  sales_cash2 = 0
}else{
  sales_cash2 = sales_cash
}

    return(

        <fregment>
            <Header></Header>
      <Sidebar></Sidebar>

<main id="main" className="main">

<div className="pagetitle">
  <h1>Sales & Payments Summary</h1>
  <div className="seprator"></div>
  <nav className="export-relative">
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <a href="javascript:;">Sales Reports</a>
      </li>         
      <li className="breadcrumb-item active">Sales & Payments Summary</li>
    </ol>
   {/*  <DownloadTableExcel filename="sales and payment summary" sheet="sales and payment summary"  currentTableRef={tableRef.current}>
      <button className="export-data"><i className="icon-excel"></i> Export Data</button>
      </DownloadTableExcel> */}

{/* <button className="export-data" id="print_transaction" onClick={Print}><i className="bi bi-printer-fill" ></i> Print</button> */}
  </nav>
</div>

<section className="section">
  <div className="row">       

    <div className="col-lg-12">

      <div className="card">
        <div className="card-body">
          
          <div className="top-form">
            
            <form>
              <div className="row">
                <div className="col date-ic">
                  <label for="selectdate"></label>
                  <DateRangePickerComp alert={parentAlert}></DateRangePickerComp>
                  {/* <div className="calendar-ic">
                    <button type="button" onClick={getCalendarValue}><i className="icon-calendar-ic"></i></button>
                  </div> */}
                  <button type="button" className="go-btn" onClick={getCalendarValue}>Go</button>
                </div>
                <div className="col search-ic">
                  <label for="selectdate"></label>
                  {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e)=>setQuery(e.target.value)}
                          /> */}
                  {/* <div className="calendar-ic">
                   <button><i className="icon-search"></i></button> 
                  </div> */}
                </div>
              </div>
            </form>
          </div>
          <div id="alldata">
          {loading?'':
         
          <section className="section pst-boxes">
            <div className="row">
              <div className="col-lg-6">          
               

                <table className="table ">
                    <tr>
                        <td>Transactions</td>
                        <td>Gross Sales</td>
                        <td>Net Sales</td>
                    </tr>

                    <tr>
                        <th>{transaction_count}</th>
                        <th>${gross_sales}</th>
                        <th>${net_sales}</th> 
                    </tr>
                </table>
</div>
                <div className="col-lg-6">          
                 <table className="table table-striped table-bordered " style={{border:"1px solid #ccc"}}>
                    <tr>
                        <td>Services</td>
                        <td>Products</td>
                        <td>Gifts</td>
                    </tr>

                    <tr>
                        <th>{service_count2}</th>
                        <th>{product_count2}</th>
                        <th>{gift_card_count2}</th>
                    </tr>
                    <tr>
                        <th>${service_total2}</th>
                        <th>${product_total2}</th>
                        <th>${gift_card_total2}</th>
                    </tr>
                </table>
             </div>
            </div>
          </section>
}
          {loading?<Loader></Loader>:
          <div className="table-responsive orderid-select">              
            <table className="table  table-bordered" ref={tableRef}>
              <thead>
                <tr>
                  <th scope="col"><a className="blk " href="javascript:void(0);"></a></th>
                  <th scope="col"><a className="blk " href="javascript:void(0);">Cash</a></th>
                  <th scope="col"><a className="blk " href="javascript:void(0);">Debit</a></th>
                  <th scope="col"><a className="blk " href="javascript:void(0);">Credit</a></th>
                  <th scope="col"><a className="blk " href="javascript:void(0);">Gift Card</a></th>
                  <th scope="col"><a className="blk " href="javascript:void(0);">Credit Cert.</a></th>
                  <th scope="col"><a className="blk " href="javascript:void(0);">Reward</a></th>
                  <th scope="col"><a className="blk " href="javascript:void(0);">Discounts</a></th>
                  {/* <th scope="col"><a className="blk " href="javascript:void(0);">Tax Refunds</a></th> */}
                  <th scope="col"><a className="blk " href="javascript:void(0);">Total</a></th>
                </tr>
              </thead>
              <tbody>
               
                
              <tr style={{backgroundColor:"#f8f9faed"}}>

                  <td style={{textAlign:"left"}}>Sales</td>
                  <td style={{textAlign:"right"}}>${sales_cash2}</td>
                  <td style={{textAlign:"right"}}>${sales_debit}</td>
                  <td style={{textAlign:"right"}}>${sales_credit}</td>
                  <td style={{textAlign:"right"}}>${sales_gift_card}</td>
                  <td style={{textAlign:"right"}}>${sales_gift_certificate?sales_gift_certificate:'0.00'}</td>
                  <td style={{textAlign:"right"}}>${reward_amount}</td>
                  <td style={{textAlign:"right"}}></td>
                  {/* <td style={{textAlign:"right"}}>-${sales_tax_refund}</td> */}
                  <td style={{textAlign:"right"}}>${sales_total}</td>
              </tr>

              <tr>

                  <td style={{textAlign:"left"}}>Refunds</td>
                  <td style={{textAlign:"right"}}>-${refund_cash}</td>
                  <td style={{textAlign:"right"}}>-${refund_debit}</td>
                  <td style={{textAlign:"right"}}>-${refund_credit}</td>
                  <td style={{textAlign:"right"}}>-${refund_gift_card}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {/* <td></td> */}
                  <td style={{textAlign:"right"}}>-${refund_total}</td>
              </tr>

              <tr style={{backgroundColor:"#f8f9faed"}}>

                  <td style={{textAlign:"left"}}>Discounts</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{textAlign:"right"}}>-${discount_discount}</td>
                  {/* <td></td> */}
                  <td></td>
              </tr>
           

              <tr style={{backgroundColor:"#f8f9faed"}}>

                 <td colSpan="10" style={{textAlign:"left"}}>Tax</td>

              </tr>


              {

              tax_info && Object.entries(tax_info).map(([key, value]) => (  
                 
                  <tr key={key}>
                      <td style={{textAlign:"left"}}>{key}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/* <td></td> */}
                      <td></td>
                  
                      <td  style={{textAlign:"right"}}>$
                        
                         {value.total?parseFloat(value.total).toFixed(2):'0.00'}
                       
                       
                      </td>
                  </tr>
                    


                ))}


                <tr style={{backgroundColor:"#f8f9faed"}}>

                <td colSpan="10" style={{textAlign:"left"}}>Tax Refund</td>

                </tr>


                {

                tax_refund && Object.entries(tax_refund).map(([key, value]) => (  

                  
                <tr key={key}>
                    <td style={{textAlign:"left"}}>{key}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td  style={{textAlign:"right"}}>-${value.total?parseFloat(value.total).toFixed(2):'0.00'}</td>
                    
                </tr>
                  


                  ))}
           



                <tr style={{backgroundColor:"#f8f9faed"}}>

                    <td style={{textAlign:"left"}}>Tips</td>
                    <td style={{textAlign:"right"}}>${tips?.cash?parseFloat(tips?.cash).toFixed(2):'0.00'}</td>
                    <td style={{textAlign:"right"}}>${tips?.debit?parseFloat(tips?.debit).toFixed(2):'0.00'}</td>
                    <td style={{textAlign:"right"}}>${tips?.credit?parseFloat(tips?.credit).toFixed(2):'0.00'}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td  style={{textAlign:"right"}}> ${tips?.total?parseFloat(tips?.total).toFixed(2):'0.00'}</td>
                </tr>

                <tr>

                    <td style={{textAlign:"left"}}>Deposit</td>
                    <td></td>
                    <td></td>
                    <td  style={{textAlign:"right"}}>${deposit?.total?parseFloat(deposit?.total).toFixed(2):'0.00'}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    
                </tr>

                <tr style={{backgroundColor:"#f8f9faed"}}>

                    <td style={{textAlign:"left"}}>Cash Discount</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td  style={{textAlign:"right"}}>-${cash_discount?parseFloat(cash_discount).toFixed(2):'0.00'}</td>
                  
                    <td></td>
                </tr>

               
                                                 
              </tbody>

              <tfoot style={{position:"sticky",bottom:"0"}}>
              <tr style={{backgroundColor:"#f8f9faed"}}>

              <td style={{textAlign:"left"}}><b>Grand Total</b></td>

              <td style={{textAlign:"right",position:"sticky",bottom:"0"}}><b>${grand_total?.cash?parseFloat(grand_total?.cash).toFixed(2):'0.00'}</b></td>
              <td style={{textAlign:"right",position:"sticky",bottom:"0"}}><b>${grand_total?.debit?parseFloat(grand_total?.debit).toFixed(2):'0.00'}</b></td>
              <td style={{textAlign:"right",position:"sticky",bottom:"0"}}><b>${grand_total?.credit?parseFloat(grand_total?.credit).toFixed(2):'0.00'}</b></td>
              <td style={{textAlign:"right",position:"sticky",bottom:"0"}}><b>-${grand_total?.gift_card?parseFloat(grand_total?.gift_card).toFixed(2):'0.00'}</b></td>
              <td style={{textAlign:"right",position:"sticky",bottom:"0"}}><b>${grand_total?.credit_certificate?parseFloat(grand_total?.credit_certificate).toFixed(2):'0.00'}</b></td>
              <td style={{textAlign:"right",position:"sticky",bottom:"0"}}><b>$0.00</b></td>
              <td style={{textAlign:"right",position:"sticky",bottom:"0"}}><b>-${grand_total?.discount?parseFloat(grand_total?.discount).toFixed(2):'0.00'}</b></td>
              {/* <td style={{textAlign:"right"}}><b>-${grand_total?.total_tax?parseFloat(grand_total?.total_tax).toFixed(2):'0.00'}</b></td> */}
              <td  style={{textAlign:"right",position:"sticky",bottom:"0"}}><b>${grand_total?.total?parseFloat(grand_total?.total).toFixed(2):'0.00'}</b></td>
              </tr>

              </tfoot>
            </table>
        </div>


       }
       </div>
        </div>
      </div>

     

    </div>
  </div>
</section>

</main>
<Footer></Footer>
        </fregment>
    )
}

export default SalesAndPaymentSummary;