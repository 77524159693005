import {React, useRef,useEffect, useState} from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import $ from 'jquery'



function Sidebar() {



  const ref = useRef()



  //const vendor_id = localStorage.getItem('vendor_id');


  //"@media screen and (min-width:400px) { div { color: red; } }";

//bi bi-list toggle-sidebar-btn

 /*  $(document).mouseup(function(e) {

    if ($(".toggle-sidebar")[0]){
    
    var container = $("#sidebar");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
      $('body').removeClass('toggle-sidebar');
    }
  }

}); */


  function handleSidebar(e){


    $('body').removeClass('toggle-sidebar');

  } 



  const params = useParams();
  let vendor_id  = params.vendor_id;

let urlParams = window.location.href;


let abc = urlParams.split('/');

console.log(abc);
var hostname = window.location.hostname;
if(hostname=='localhost'){
  var fileName = abc[4];

}else{
  var fileName = abc[6];
}


if(fileName=='transaction-list' || fileName=='sales-and-payment-summary' || fileName=='discount'  || fileName=='void'  || fileName=='credit-certificate'  || fileName=='egift'  || fileName=='services'  || fileName=='products' || fileName=='refund' ){
  var expand_sales_report = "show";
  //alert('a')
}else{
  var expand_sales_report = "";
}


if(fileName=='current-stock'   || fileName=='low-order-qty'  || fileName=='stock-analysis'  || fileName=='profit-margin'  || fileName=='purchase-history'  ){
  var expand_inventory_report = "show";
}else{
  var expand_inventory_report = "";
}

if(fileName=='appointment-summary'   || fileName=='booking-method'){
  var expand_appointment_report = "show";
}else{
  var expand_appointment_report = "";
}


if(fileName=='active-employee-list'   || fileName=='employee-profile' || fileName=='employee-profile' || fileName=='payrate-and-commission' || fileName=='commission-summary' || fileName=='attendance-detail' || fileName=='hours-and-wages-summary' || fileName=='employee-sales-summary'){
  var expand_employee_report = "show";
}else{
  var expand_employee_report = "";
}

if(fileName=='customer-list'   || fileName=='customer-appointment' || fileName=='customer-sales' || fileName=='customer-egift-card' || fileName=='customer-credit-certificate'){
  var expand_customer_report = "show";
}else{
  var expand_customer_report = "";
}

if(fileName=='general-program'   || fileName=='event-based'){
  var expand_loyalty_report = "show";
}else{
  var expand_loyalty_report = "";
}


  return (

 

    <fregment>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <NavLink className="nav-link collapsed" to={"/dashboard/"+vendor_id} onClick={handleSidebar}>
              <i className="ic-sizes icon-dashboard"></i>
              <span>Dashboard</span>
            </NavLink>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-target="#components-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="ic-sizes icon-sales-reports"></i>
              <span>Sales Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="components-nav"
              className={"nav-content collapse" + expand_sales_report }
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <NavLink to={"/transaction-list/"+vendor_id} onClick={handleSidebar}>
                  <span>Transaction List</span>
                </NavLink> 

              
              </li>
              <li>
              <NavLink to={"/sales-and-payment-summary/"+vendor_id} onClick={handleSidebar}>
                  <span>Sales & Payments Summary</span>
                  </NavLink> 
              </li>
              <li>
                <NavLink to={"/discount/"+vendor_id} onClick={handleSidebar}>
                  <span>Discount</span>
                </NavLink>
              </li>
              
              <li>
                <NavLink to={"/credit-certificate/"+vendor_id} onClick={handleSidebar}>
                  <span>Certificate</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/egift/"+vendor_id} onClick={handleSidebar}>
                  <span>E-Gift Card</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/services/"+vendor_id} onClick={handleSidebar}>
                  <span>Services</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/products/"+vendor_id} onClick={handleSidebar}>
                  <span>Products</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to={"/refund/"+vendor_id}>
                  <span>Refund</span>
                </NavLink>
              </li> */}
            </ul>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#forms-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="ic-sizes icon-inventory-reports"></i>
              <span>Inventory Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="forms-nav"
              className={"nav-content collapse " + expand_inventory_report}
              data-bs-parent="#sidebar-nav"
            >
              <li>
              <NavLink to={"/current-stock/"+vendor_id} onClick={handleSidebar}>
                  <span>Current Stock</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/low-order-qty/"+vendor_id} onClick={handleSidebar}>
                  <span>Low Order Quantity</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/stock-analysis/"+vendor_id} onClick={handleSidebar}>
                  <span>Stock Analysis</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/profit-margin/"+vendor_id} onClick={handleSidebar}>
                  <span>Profit Margin</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/purchase-history/"+vendor_id} onClick={handleSidebar}>
                  <span>Purchase History</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#tables-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="ic-sizes icon-appointment-reports"></i>
              <span>Appointment Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="tables-nav"
              className={"nav-content collapse "+expand_appointment_report}
              data-bs-parent="#sidebar-nav"
            >
              <li>
              <NavLink to={"/appointment-summary/"+vendor_id} onClick={handleSidebar}>
                  <span>Appointment Summary</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/booking-method/"+vendor_id} onClick={handleSidebar}>
                  <span>Booking Method</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#charts-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="ic-sizes icon-employee-reports"></i>
              <span>Employee Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="charts-nav"
              className={"nav-content collapse " + expand_employee_report}
              data-bs-parent="#sidebar-nav"
            >
              <li>
              <NavLink to={"/active-employee-list/"+vendor_id} onClick={handleSidebar}>
                  <span>Active Employee List</span>
                </NavLink>
              </li>
              <li>
                
                <NavLink to={"/employee-profile/"+vendor_id} onClick={handleSidebar}>
                  <span>Employee Profiles</span>
                </NavLink>
               
              </li>
              <li>
              <NavLink to={"/payrate-and-commission/"+vendor_id} onClick={handleSidebar}>
                  <span>Pay Rate & Commission</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/commission-summary/"+vendor_id} onClick={handleSidebar}>
                  <span>Commission Summary</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/attendance-detail/"+vendor_id} onClick={handleSidebar}>
                  <span>Attendance Detail</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/hours-and-wages-summary/"+vendor_id} onClick={handleSidebar}> 
                  <span>Hours & Wages Summary</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/employee-sales-summary/"+vendor_id} onClick={handleSidebar}>
                  <span>Sales Summary</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#icons-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="ic-sizes icon-customer-reports"></i>
              <span>Customer Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="icons-nav"
              className={"nav-content collapse "+ expand_customer_report}
              data-bs-parent="#sidebar-nav"
            >
              <li>
              <NavLink to={"/customer-list/"+vendor_id} onClick={handleSidebar}>
                  <span>Customer List</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/customer-appointment/"+vendor_id} onClick={handleSidebar}>
                  <span>Appointments</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/customer-sales/"+vendor_id} onClick={handleSidebar}>
                  <span>Customer Sales</span>
                </NavLink>
              </li>
              <li>
                
                <NavLink to={"/customer-egift-card/"+vendor_id} onClick={handleSidebar}>
                  <span>E-Gift Card</span>
                </NavLink>
               
              </li>
              <li>
              <NavLink to={"/customer-credit-certificate/"+vendor_id} onClick={handleSidebar}>
                  <span>Certificate</span>
                </NavLink>
              </li>
            </ul>
          </li>


          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#icons-nav-loyalty"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="ic-sizes icon-customer-reports"></i>
              <span>Loyalty Points</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="icons-nav-loyalty"
              className={"nav-content collapse "+ expand_loyalty_report}
              data-bs-parent="#sidebar-nav"
            >
              <li>
              <NavLink to={"/general-program/"+vendor_id} onClick={handleSidebar}>
                  <span>General Program</span>
                </NavLink>
              </li>
              <li>
              <NavLink to={"/event-based/"+vendor_id} onClick={handleSidebar}>
                  <span>Event Based</span>
                </NavLink>
              </li>
              <li>
              
              </li>
            </ul>
          </li>


        </ul>
      </aside>
    </fregment>
  );
}

export default Sidebar;
