import React, {useEffect, useState, useRef, PureComponent} from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 


 

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, LineChart, Line, ReferenceLine  } from 'recharts';


//{(R2-R1)/R1]*100.
import $ from 'jquery'
import 'jquery-ui-dist/jquery-ui'


import './dashboard.css'

import { Alert } from "antd";


const api_baseurl = process.env.REACT_APP_BASE_URL_API



function Dashboard(){

  const [data, setData] = useState([]);
  const [servieProviderData, setServieProviderData] = useState([]);
  const [productCategoryData, setProductCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [saleData, setSaleData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [saleDate, setSaleDate] = useState("");
  const [provider, setProvider] = useState("");
  const [productCategoryId, setProductCategoryId] = useState("");
  const [productId, setProductId] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [inventoryDaterange, setInventoryDaterange] = useState(new Date());



  const tableRef = useRef(null);

  const params = useParams();
  let vendor_id  = atob(params.vendor_id);

  let newDate = new Date().toISOString().slice(0,10);
/* let date = newDate.getDate();
let month = newDate.getMonth();
let year = newDate.getFullYear();

let current_date = month+'/'+date+'/'+year; */
//alert(newDate)
//$("#start_date").val(current_date)
//document.getElementById("start_date").value = "2014-02-09";

$('#sales_date').val(newDate);
//document.getElementById("sales_date").value = current_date;

var single = appointmentData.single_service_count?appointmentData.single_service_count:'0';
var multiple = appointmentData.multiple_service_count?appointmentData.multiple_service_count:'0';
var group = appointmentData.group_service_count?appointmentData.group_service_count:'0';

var single_amount = appointmentData.single_service_sales?appointmentData.single_service_sales:'0';
var multiple_amount = appointmentData.multiple_service_sales?appointmentData.multiple_service_sales:'0';
var group_amount = appointmentData.group_service_sales?appointmentData.group_service_sales:'0';

//PIE CHART DATA
  const data_pai = [
    { name: "Single ("+parseInt(single)+") USD" , value:  parseInt(single_amount) },
    { name: "Multiple ("+parseInt(multiple)+") USD", value:  parseInt(multiple_amount)},
    { name: "Group ("+parseInt(group)+") USD", value:  parseInt(group_amount)}
  ];

  const COLORS = ["#0088FE", "#00C49F", "#955dff", "#FF8042"];




  // CUSTOMER CHART
  const data_customer = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  


function parentAlert_sales(demoData){
  console.log('sale data date: ',demoData);
  setStartDate(demoData)

}

  function parentAlert_appointment(demoData) {

    //setInventoryDaterange(demoData)
    //alert(demoData)
    setStartDate2(demoData)
    //alert(demoData)
   // console.log('inventory date range : ',inventoryDaterange)

  }


  function parentAlert_inventory(){

  }

  useEffect(() => {
   
    loadData()
    getServiceProvider()
    getSaleData()
    getAppointmentData()
    getProductCategory()
    getInventoryData()
  }, []);

  function loadData() {
    setLoading(true)
    fetch(api_baseurl+"dashboard?vendor_id="+vendor_id).then(
      (res) => {
        res.json().then((response) => {
          setData(response);
          setLoading(false)
        });
      }
    );
  }

  function getServiceProvider(){
   
    fetch(api_baseurl+"getServiceProvider?vendor_id="+vendor_id).then(
      (res) => {
        res.json().then((response) => {
          setServieProviderData(response);
        
        });
      }
    );

  }


  function getProductCategory(){
    
    fetch(api_baseurl+"getProductCategory?vendor_id="+vendor_id).then(
      (res) => {
        res.json().then((response) => {
          setProductCategoryData(response);
        
        });
      }
    );

  }

  function getProductByCategoryId(category_id){
    
    fetch(api_baseurl+"getProductByCategoryId?vendor_id="+vendor_id+"&category_id="+category_id).then(
      (res) => {
        res.json().then((response) => {
          setProductData(response);
        
        });
      }
    );

  } 


  function getSaleDate(e){


      setSaleDate(e.target.value)

  }

  function getSaleData(startDate,provider){

   if(startDate=='' || startDate==undefined || startDate=='undefined'){
     var datefilter = ''
   }else{
   var datefilter =  startDate
  }


  if(provider=='' || provider==undefined || provider=='undefined'){
    var stylist_id = '0'
  }else{
  var stylist_id =  provider
 }
  

//console.log('daateeeee',datefilter);
 
    fetch(api_baseurl+"getSaleData?vendor_id="+vendor_id+"&date_range=" +datefilter+"&stylist_id="+stylist_id).then(
      (res) => {
        res.json().then((response) => {
          setSaleData(response);
        
        });
      }
    );
  }


  function getAppointmentData(startDate2,provider){

    if(startDate2=='' || startDate2==undefined || startDate2=='undefined'){
      var datefilter = '';
    }else{
    var datefilter =  startDate2
   }
 
 
   if(provider=='' || provider==undefined || provider=='undefined'){
     var stylist_id = '0'
   }else{
   var stylist_id =  provider
  }
   
  //alert(datefilter)
  
 
     fetch(api_baseurl+"getAppointmentChart?vendor_id="+vendor_id+"&date_range=" +datefilter+"&stylist_id="+stylist_id).then(
       (res) => {
         res.json().then((response) => {
          setAppointmentData(response);
         
         });
       }
     );

    
   }


   function getInventoryData(){
     

    if(productCategoryId=='' || productCategoryId==undefined || productCategoryId=='undefined'){
      var category_id = 0;
    }else{
    var category_id =  productCategoryId
   }
 
 var pid = $('#product_dropdown').val();

   if(pid=='' || pid==undefined || pid=='undefined'  || pid==null){
     var product_id = '0'
   }else{
   var product_id =  pid
  }

  var datefilter = inventoryDaterange

  //alert(datefilter);

  console.log('inventory : ',category_id, product_id, datefilter)

  fetch(api_baseurl+"getInventoryData2?vendor_id="+vendor_id+"&date_range="+datefilter+"&category_id="+category_id+"&product_id="+product_id).then(
       (res) => {
         res.json().then((response) => {
          setInventoryData(response);
         
         });
       }
     );


  
  }




  //console.log('apt data: ',appointmentData)
  function handleProviderSelect(e){

    setProvider(e.target.value)

}

function handleProviderSelectAppointment(e){

  setProvider(e.target.value)

}

function filterByProvider(){


  var startDate_1 = startDate

  getSaleData(startDate_1,provider)

}

function filterByProviderAppointment(){

 // var startDate_2 = startDate2.toISOString().slice(0,10)

  var startDate_2 = startDate2

  getAppointmentData(startDate_2,provider)

}



function handleProductCategory(e){

  setProductCategoryId(e.target.value)

  getProductByCategoryId(e.target.value)

 

}

function handleProduct(e){

    setProductId(e.target.value)
    
}

   

   

  //console.log('this is sale data ',saleData)


  const data2 = [
    {
      name: 'Service',
     
     
      USD: saleData.service_sales?parseFloat(saleData.service_sales):'0.00',
     
    },
    {
      name: 'Product',
    
     
      USD: saleData.product_sales?parseFloat(saleData.product_sales):'0.00',
    },
    {
      name: 'Gift Card',
     
     
      USD: saleData.giftcard_sales?parseFloat(saleData.giftcard_sales):'0.00',
    },
    
  ];
  

  console.log(saleData)



 

  let todays_sale = parseFloat(data.result?.todays_sale);
  let yesterday_sale = parseFloat(data.result?.yesterday_sale);
  let num_of_service_today = parseInt(data.result?.num_of_service_today);
  let num_of_service_yesterday = parseInt(data.result?.num_of_service_yesterday);
  let num_of_product_today = parseInt(data.result?.num_of_product_today);
  let num_of_product_yesterday = parseInt(data.result?.num_of_product_yesterday);
  let refund_amount_today = parseFloat(data.result?.refund_amount_today);
  let refund_amount_yesterday = parseFloat(data.result?.refund_amount_yesterday);
  let customer_visit_today = parseInt(data.result?.customer_visit_today);
  let customer_visit_yesterday = parseInt(data.result?.customer_visit_yesterday);
  let cancelled_apt_today = parseInt(data.result?.cancelled_apt_today);
  let cancelled_apt_yesterday = parseInt(data.result?.cancelled_apt_yesterday);
  let discount_today = parseFloat(data.result?.discount_today);
  let discount_yesterday = parseFloat(data.result?.discount_yesterday);
  let num_of_apt_today = parseInt(data.result?.num_of_apt_today);
  let num_of_apt_yesterday = parseInt(data.result?.num_of_apt_yesterday);

  //{(Today-Yesterday)/Yesterday]*100}
  let sale_percentage = ((todays_sale-yesterday_sale)/yesterday_sale)*100;
  let product_percentage = ((num_of_product_today-num_of_product_yesterday)/num_of_product_yesterday)*100;
  let service_percentage = ((num_of_service_today-num_of_service_yesterday)/num_of_service_yesterday)*100;
  let refund_percentage = ((refund_amount_today-refund_amount_yesterday)/refund_amount_yesterday)*100;
  let customer_percentage = ((customer_visit_today-customer_visit_yesterday)/customer_visit_yesterday)*100;
  let cancelled_percentage = ((cancelled_apt_today-cancelled_apt_yesterday)/cancelled_apt_yesterday)*100;
  let discount_percentage = ((discount_today-discount_yesterday)/discount_yesterday)*100;
  let appointment_percentage = ((num_of_apt_today-num_of_apt_yesterday)/num_of_apt_yesterday)*100;



  if(isNaN(sale_percentage)){
    sale_percentage = 0.00+'%';
  }else if(sale_percentage=='Infinity'){
    sale_percentage = '-';
  }else{
    sale_percentage = sale_percentage.toFixed(2) + '%'
  }
 

  if(isNaN(service_percentage)){
    service_percentage = 0.00+'%';
  }else if(service_percentage=='Infinity'){
    service_percentage = '-';
  }else{
    service_percentage = service_percentage.toFixed(2)+'%'
  }

  if(isNaN(product_percentage)){
    product_percentage = 0.00+'%';
  }else if(product_percentage=='Infinity'){
    product_percentage = '-';
  }else{
    product_percentage = product_percentage.toFixed(2)+'%'
  }





  if(isNaN(refund_percentage)){
    refund_percentage = 0.00+'%';
  }else if(refund_percentage=='Infinity'){
    refund_percentage = '-';
  }else{
    refund_percentage = refund_percentage.toFixed(2)+'%'
  }

  if(isNaN(appointment_percentage)){
    appointment_percentage = 0.00+'%';
  }else if(appointment_percentage=='Infinity'){
    appointment_percentage = '-';
  }else{
    appointment_percentage = appointment_percentage.toFixed(2)+'%'
  }



  if(isNaN(customer_percentage)){
    customer_percentage = 0.00+'%';
  }else if(customer_percentage=='Infinity'){
    customer_percentage = '-';
  }else{
    customer_percentage = customer_percentage.toFixed(2)+'%'
  }

  if(isNaN(cancelled_percentage)){
    cancelled_percentage = 0.00+'%';
  }else if(cancelled_percentage=='Infinity'){
    cancelled_percentage = '-';
  }else{
    cancelled_percentage = cancelled_percentage.toFixed(2)+'%'
  }

  if(isNaN(discount_percentage)){
    discount_percentage = 0.00+'%';
  }else if(discount_percentage=='Infinity'){
    discount_percentage = '-';
  }else{
    discount_percentage = discount_percentage.toFixed(2)+'%'
  }

  
 
if(sale_percentage!='-'){
  if(todays_sale>yesterday_sale){
    var incClass = "green-increasing";
    var arrow = "bi bi-arrow-up";
  }else  if(todays_sale==yesterday_sale){
    var incClass = "balanced";
    var arrow = "bi bi-arrow-left-right";
  }else{
    var incClass = "decreasing";
    var arrow = "bi bi-arrow-down";
  }
}else{
  if(todays_sale>yesterday_sale){
    var incClass = "green-increasing";
    var arrow = "";
  }else{
    var incClass = "decreasing";
    var arrow = "";
  }
  
}


if(service_percentage!='-'){
  if(num_of_service_today>num_of_service_yesterday){
    var incClass2 = "green-increasing";
    var arrow2 = "bi bi-arrow-up";
  }else if(num_of_service_today==num_of_service_yesterday){
    var incClass2 = "balanced";
    var arrow2 = "bi bi-arrow-left-right";
  }else{
    var incClass2 = "decreasing";
    var arrow2 = "bi bi-arrow-down";
  }
}else{
  if(num_of_service_today>num_of_service_yesterday){
    var incClass2 = "green-increasing";
    var arrow2 = "";
  }else{
    var incClass2 = "decreasing";
    var arrow2 = "";
  }
  
  }


  //console.log('hellooooo ',num_of_product_yesterday);


if(product_percentage!='-'){
  if(num_of_product_today>num_of_product_yesterday){
    var incClass3 = "green-increasing";
    var arrow3 = "bi bi-arrow-up";
  }else if(num_of_product_today==num_of_product_yesterday){
    var incClass3 = "balanced";
    var arrow3 = "bi bi-arrow-left-right";
  }else{
    var incClass3 = "decreasing";
    var arrow3 = "bi bi-arrow-down";
  }
}else{
  if(num_of_product_today>num_of_product_yesterday){
    var incClass3 = "green-increasing";
    var arrow3 = "";
  }else{
    var incClass3 = "decreasing";
    var arrow3 = "";
  }
 
}

if(refund_percentage!='-'){
  if(refund_amount_today>refund_amount_yesterday){
    var incClass4 = "green-increasing";
    var arrow4 = "bi bi-arrow-up";
  }else if(refund_amount_today==refund_amount_today){
    var incClass4 = "balanced";
    var arrow4 = "bi bi-arrow-left-right";
  }else{
    var incClass4 = "decreasing";
    var arrow4 = "bi bi-arrow-down";
  }
}else{
  if(refund_amount_today>refund_amount_yesterday){
    var incClass4 = "green-increasing";
    var arrow4 = "";
  }else{
    var incClass4 = "decreasing";
    var arrow4 = "";
  }
 
}

if(appointment_percentage!='-'){
  if(num_of_apt_today>num_of_apt_yesterday){
    var incClass5 = "green-increasing";
    var arrow5 = "bi bi-arrow-up";
  }else if(num_of_apt_today==num_of_apt_yesterday){
    var incClass5 = "balanced";
    var arrow5 = "bi bi-arrow-left-right";
  }else{
    var incClass5 = "decreasing";
    var arrow5 = "bi bi-arrow-down";
  }
}else{
  if(num_of_apt_today>num_of_apt_yesterday){
    var incClass5 = "green-increasing";
    var arrow5 = "";
  }else{
    var incClass5 = "decreasing";
    var arrow5 = "";
  }
 
}

if(customer_percentage!='-'){
  if(customer_visit_today>customer_visit_yesterday){
    var incClass6 = "green-increasing";
    var arrow6 = "bi bi-arrow-up";
  }else if(customer_visit_today==customer_visit_yesterday){
    var incClass6 = "balanced";
    var arrow6 = "bi bi-arrow-left-right";
  }else{
    var incClass6 = "decreasing";
    var arrow6 = "bi bi-arrow-down";
  }
}else{

  if(customer_visit_today>customer_visit_yesterday){
    var incClass6 = "green-increasing";
    var arrow6 = "";
  }else{
    var incClass6 = "decreasing";
    var arrow6 = "";
  }

  
}

if(cancelled_percentage!='-'){
  if(cancelled_apt_today>cancelled_apt_yesterday){
    var incClass7 = "green-increasing";
    var arrow7 = "bi bi-arrow-up";
  }else if(cancelled_apt_today==cancelled_apt_yesterday){
    var incClass7 = "balanced";
    var arrow7 = "bi bi-arrow-left-right";
  }else{
    var incClass7 = "decreasing";
    var arrow7 = "bi bi-arrow-down";
  }
}else{
  if(cancelled_apt_today>cancelled_apt_yesterday){
  var incClass7 = "green-increasing";
  var arrow7 = "";
  }else{
    var incClass7 = "decreasing";
    var arrow7 = "";
  }

  
}


if(discount_percentage!='-'){
  if(discount_today>discount_yesterday){
    var incClass8 = "green-increasing";
    var arrow8 = "bi bi-arrow-up";
  }else if(discount_today==discount_yesterday){
    var incClass8 = "balanced";
    var arrow8 = "bi bi-arrow-left-right";
  }else{
    var incClass8 = "decreasing";
    var arrow8 = "bi bi-arrow-down";
  }
}else{
  if(discount_today>discount_yesterday){
    var incClass8 = "green-increasing";
    var arrow8 = "";
  }else{
    var incClass8 = "decreasing";
    var arrow8 = "";
  }
 
}




if(sale_percentage!='-'){
  var sale_percentage2 = sale_percentage.replace('-','');
}else{
  var sale_percentage2 = sale_percentage;
}

if(service_percentage!='-'){
  var service_percentage2 = service_percentage.replace('-','');
}else{
  var service_percentage2 = service_percentage;
}

if(product_percentage!='-'){
var product_percentage2 = product_percentage.replace('-','');
}else{
var product_percentage2 = product_percentage;
}

if(refund_percentage!='-'){
var refund_percentage2 = refund_percentage.replace('-','');
}else{
  var refund_percentage2 = refund_percentage;
}

if(appointment_percentage!='-'){
var appointment_percentage2 = appointment_percentage.replace('-','');
}else{
  var appointment_percentage2 = appointment_percentage;
}

if(customer_percentage!='-'){
var customer_percentage2 = customer_percentage.replace('-','');
}else{
  var customer_percentage2 = customer_percentage.replace('-','');
}

if(cancelled_percentage!='-'){
  var cancelled_percentage2 = cancelled_percentage.replace('-','');
}else{
  var cancelled_percentage2 = cancelled_percentage;
}

if(discount_percentage!='-'){
var discount_percentage2 = discount_percentage.replace('-','');
}else{
  var discount_percentage2 = discount_percentage;

}



  //INVENTORY CHART

  /* var day_0 = inventoryData.days_name[0];
  var day_1 = inventoryData.days_name[1];
  var day_2 = inventoryData.days_name[2];
  var day_3 = inventoryData.days_name[3];
  var day_4 = inventoryData.days_name[4];
  var day_5 = inventoryData.days_name[5];
  var day_6 = inventoryData.days_name[6];  */

/*   var order_count0 = inventoryData.days_ordered[0];
  var order_count1 = inventoryData.days_ordered[1];
  var order_count2 = inventoryData.days_ordered[2];
  var order_count3 = inventoryData.days_ordered[3];
  var order_count4 = inventoryData.days_ordered[4];
  var order_count5 = inventoryData.days_ordered[5];
  var order_count6 = inventoryData.days_ordered[6];  */


  //console.log('inventory data: ',inventoryData)

  const data_inventory = [
    {
      name: 'Mon',
      InStock: 400,
      Ordered: 300,
      Returned: 100,
    },
    {
      name: 'Tue',
      InStock: 400,
      Ordered: 300,
      Returned: 100,
    },
    {
      name: 'Wed',
      InStock: 400,
      Ordered: 300,
      Returned: 100,
    },
    {
      name: 'Thu',
      InStock: 400,
      Ordered: 300,
      Returned: 100,
    },
    {
      name: 'Fri',
      InStock: 400,
      Ordered: 300,
      Returned: 100,
    },
    {
      name: 'Sat',
      InStock: 400,
      Ordered: 300,
      Returned: 100,
    },
    {
      name: 'Sun',
      InStock: 400,
      Ordered: 300,
      Returned: 100,
    },
  ];


  
  const colors2 = ['#00C49F', '#0088FE', '#FF8042'];

let in_stock = parseInt(inventoryData?.instock)
let ordered = parseInt(inventoryData?.ordered)
let returned = parseInt(inventoryData?.returned)


console.log('inventory data: ',inventoryData)

  const data2_inventory = [
    {
      name: 'In Stock',
      count: in_stock,
     
    },
    {
      name: 'Ordered',
      count: ordered,
     
    },
    {
      name: 'Returned',
      count: returned,
     
    },
    
  ];





    return(


   

      
        <fregment>
            <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main" style={{overflow:"hidden"}}>

      <div className="pagetitle">
    <h1>Today's key numbers</h1>          
</div>



<section className="today-key">
    <div className="">
        <div className="row row-cols-lg-4 g-lg-4 row-cols-sm-12 g-sm-12">

            <div className="col">
            
            <div className="sm-boxes sales">
                <h4>Sales</h4>
                <p className="yesterday">Yesterday: ${isNaN(yesterday_sale.toFixed(2))?'0.00':yesterday_sale.toFixed(2)}</p>
                <p className="bold-text">${isNaN(todays_sale.toFixed(2))?'0.00':todays_sale.toFixed(2)}</p>
                <span className={incClass}>{sale_percentage2} <i className={arrow}></i></span>
                
            </div>
          
            </div>
            
            <div className="col">
            <div className="sm-boxes">
                <h4>Services</h4>
                <p className="yesterday">Yesterday: {isNaN(num_of_service_yesterday)?'0':num_of_service_yesterday}</p>
                <p className="bold-text">{isNaN(num_of_service_today)?'0':num_of_service_today}</p>
                <span className={incClass2}>{service_percentage2} <i className={arrow2}></i></span>
            </div>              
            </div>

            <div className="col">
            <div className="sm-boxes">
                <h4>Products</h4>
                <p className="yesterday">Yesterday: {isNaN(num_of_product_yesterday)?'0':num_of_product_yesterday}</p>
                <p className="bold-text">{isNaN(num_of_product_today)?'0':num_of_product_today}</p>
                <span className={incClass3}>{product_percentage2} <i className={arrow3}></i></span>
            </div>
            </div>

            <div className="col">
            <div className="sm-boxes">
                <h4>Refunds</h4>
                <p className="yesterday">Yesterday: ${isNaN(refund_amount_yesterday.toFixed(2))?'0.00':refund_amount_yesterday.toFixed(2)}</p>
                <p className="bold-text">${isNaN(refund_amount_today.toFixed(2))?'0.00':refund_amount_today.toFixed(2)}</p>
                <span className={incClass4}>{refund_percentage2} <i className={arrow4}></i></span>
            </div>
            </div>

            <div className="col">
            <div className="sm-boxes">
                <h4>Appointments</h4>
                <p className="yesterday">Yesterday: {isNaN(num_of_apt_yesterday)?'0':num_of_apt_yesterday}</p>
                <p className="bold-text">{isNaN(num_of_apt_today)?'0':num_of_apt_today}</p>
                <span className={incClass5}>{appointment_percentage2} <i className={arrow5}></i></span>
            </div>
            </div>
            
            <div className="col">
            <div className="sm-boxes">
                <h4>Customer Visits</h4>
                <p className="yesterday">Yesterday: {isNaN(customer_visit_yesterday)?'0':customer_visit_yesterday}</p>
                <p className="bold-text">{isNaN(customer_visit_today)?'0':customer_visit_today}</p>
                <span className={incClass6}>{customer_percentage2} <i className={arrow6}></i></span>
            </div>              
            </div>

            <div className="col">
            <div className="sm-boxes">
                <h4>Cancelled Appointments</h4>
                <p className="yesterday">Yesterday: {isNaN(cancelled_apt_yesterday)?'0':cancelled_apt_yesterday}</p>
                <p className="bold-text">{isNaN(cancelled_apt_today)?'0':cancelled_apt_today}</p>
                <span className={incClass7}>{cancelled_percentage2} <i className={arrow7}></i></span>
            </div>
            </div>
            
            <div className="col">
            <div className="sm-boxes">
                <h4>Discounts</h4>
                <p className="yesterday">Yesterday: ${isNaN(discount_yesterday.toFixed(2))?'0.00':discount_yesterday.toFixed(2)}</p>
                <p className="bold-text">${isNaN(discount_today.toFixed(2))?'0.00':discount_today.toFixed(2)}</p>
                <span className={incClass8}>{discount_percentage2} <i className={arrow8}></i></span>
            </div>
            </div>

        </div>
    </div>
</section>

<section class="char-section">

    <div class="col-lg-12">

     <div class="row"> 
           <div class="col-lg-6 col-md-12">      
             <div class="card chart-card">
                 <div class="margin-bottom">
                     <h3>Sales</h3>            
                     <div class="form-group row">              
                         <div class="col-5">
                             {/* <input class="form-control" name="start_date" type="date" id="sales_date"  onChange={getSaleDate}  /> */}

                             {/* <DatePicker className="form-control" selected={startDate} onChange={(date) =>   
setStartDate(date)} />   */}

<DateRangePickerComp 
                            alert={parentAlert_sales}

                        

                          ></DateRangePickerComp>
                        
                         </div>          
                         <div class="col-5">            
                             <select id="dropdown" class="form-select provider" onChange={handleProviderSelect}>
                                 <option value="0">Providers</option>
                                 {servieProviderData.result && servieProviderData.result.map((item, index) => (
                                 <option value={item.stylist_id}>{item.service_provider}</option>
                                 
                                 ))}
                             </select>      
                         </div>
                         <div class="col-2">
                             <button class="go" type="button" onClick={filterByProvider}>Go</button>
                         </div>
                     </div>
                 </div>
                {/*  <div id="container2"></div> */}
                <div id="container2">
                <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data2}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="USD" fill="#955dff" />
          {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
</div>
             </div>
         </div>
           
         <div class="col-lg-6 col-md-12">        
           <div class="card chart-card">          
               <div class="margin-bottom">
                 <h3>Appointment</h3>          
                 <div class="form-group row">              
                     <div class="col-5">
                    {/*  <DatePicker className="form-control" selected={startDate2} onChange={(date) =>   
setStartDate2(date)} />   */}
<DateRangePickerComp 
                            alert={parentAlert_appointment}
                            

                          ></DateRangePickerComp>
                     </div>              
                     <div class="col-5">            
                     <select id="dropdown" class="form-select provider" onChange={handleProviderSelectAppointment}>
                                 <option value="0">Providers</option>
                                 {servieProviderData.result && servieProviderData.result.map((item, index) => (
                                 <option value={item.stylist_id}>{item.service_provider}</option>
                                 
                                 ))}
                             </select>        
                     </div>
                     <div class="col-2">
                     <button class="go" type="button" onClick={filterByProviderAppointment}>Go</button>
                     </div>
                 </div>
              </div>         
              <div id="">


              {<PieChart width={800} height={400}>
      <Pie
        data={data_pai}
        cx={250}
        cy={200}
        innerRadius={120}
        outerRadius={180}
        fill="#8884d8"
        paddingAngle={1}
        dataKey="value"
       
      >
        {data_pai.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart> }
              </div>

             
           </div>
         </div>
     </div>
     
     <div class="col-12">
         <div class="card chart-card">
             <div class="margin-bottom">
                 <h3>Today's Inventory</h3>
                 <div class="form-group row">
                    {/*  <div class="col-md-4 col-sm-6">
                    
<DateRangePickerComp
                            alert={parentAlert_inventory}
                            

                          ></DateRangePickerComp>
                          
                     </div>    */} 
                     <div class="col-md-3 col-sm-10">
                         <select id="Category" class="form-select" onChange={handleProductCategory}>
                           <option value="0">Select Category</option>
                         {productCategoryData.result && productCategoryData.result.map((item, index) => (
                                 <option value={item.category_id}>{item.category_name}</option>
                                 
                                 ))}
                         </select>
                       
                     </div>

                     <div class="col-md-3 col-sm-6">
                         <select id="product_dropdown" class="form-select" onChange={handleProduct}>
                         <option value="0">Select Product</option>
                         {productData.result && productData.result.map((item, index) => (
                                 <option value={item.product_id}>{item.product_name}</option>
                                 
                                 ))}
                         </select>                        
                     </div>
                     
                     <div class="col-md-1 col-sm-2">
                        <button class="go" type="button" onClick={getInventoryData}>Go</button>
                    </div>
                 </div>
             </div>        
             <div id="container12">
               
             {/* <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data_inventory}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="InStock" fill="#20c997" />
          <Bar dataKey="Ordered" fill="#0dcaf0" />
          <Bar dataKey="Returned" fill="#fd7e14" />
        </BarChart>
      </ResponsiveContainer> */}

<ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data= {data2_inventory}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
         
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          {/* <Bar dataKey="Count" fill="#955dff" /> */}
          <Bar dataKey="count" fill="#8884d8"  label={{ position: 'top' }}>
        {data2_inventory.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors2[index % 20]} />
        ))}
      </Bar>
          
        </BarChart>


      </ResponsiveContainer>
               
               </div>            
         </div>
     </div>
                    
    {/* <div class="col-12">
        <div class="card chart-card">
            <div class="margin-bottom">
                <h3>Customer</h3>       
                <div class="form-group row">        
                    <div class="col-md-4 col-sm-6">
                        <input class="form-control" name="start_date" type="date" id="start_date"/>
                    </div>                    
                    <div class="col-md-4 col-sm-6">
                        <select id="Category" class="form-select">
                        <option value="Customer">Select</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-10">
                        <select id="Category" class="form-select">
                        <option value="Source">Source</option>
                        </select>
                    </div>
                    <div class="col-md-1 col-sm-2">
                        <button class="go">Go</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div id="container12">
                    <ResponsiveContainer width="100%" height="100%">
        <LineChart width={500} height={300} data={data_customer}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>

      

                    </div>
                </div>    

                <div class="col-md-6 col-sm-12">
                    <div id="container12">
                    <PieChart width={800} height={400}>
      <Pie
        data={data_pai}
        cx={250}
        cy={200}
        innerRadius={120}
        outerRadius={180}
        fill="#8884d8"
        paddingAngle={1}
        dataKey="value"
       
      >
        {data_pai.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>

      

                    </div>
                </div>      
                
            </div>
        </div>
    </div> */}

  </div>
</section>


          </main>

        {/* <link href="assets/css/dashboard.css" rel="stylesheet"/> */}
        {/* <ScriptTag isHydrating={true} type="text/javascript" 
      src=
"./assets/js/dashboard.js" /> */}


 

    
<Footer></Footer>


    

        </fregment>

        
    )
}

export default Dashboard;