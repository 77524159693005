import React from "react";

function Footer() {
  return (
    <fregment>
      <footer id="footer" className="footer">
        <div className="copyright">
          Copyright <span> &copy; {(new Date().getFullYear())} </span>All rights reserved by Hubwallet.
        </div>
        <div className="credits">
          Powered by{" "}
          <a href="javascript:;">
            <img src="./assets/images/hubwallet-logo.svg" />
          </a>
        </div>
      </footer>

      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </fregment>
  );
}

export default Footer;
