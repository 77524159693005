import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { propTypes } from "react-bootstrap/esm/Image";
import {useNavigate, useParams} from 'react-router-dom';
import Loader from "./include/Loader";

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { ExceptionMap } from "antd/es/result";
import CryptoJS from "crypto-js";
import './Calculator.css';

const api_baseurl = process.env.REACT_APP_BASE_URL_API



function Login() {

  const [pin, setPin] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false)
  const [errorValidation, setErrorValidation] = useState(false)
  const [message, setMessage] = useState("")
  const [visible, setVisible] = useState(false)
  
  

  const params = useParams();
  


  const navigate = useNavigate();




  const [displayValue, setDisplayValue] = useState('');
  const [currentValue, setCurrentValue] = useState(null);
  const [operator, setOperator] = useState(null);

  const handleNumberClick = (num) => {
    /* if (displayValue === '0') {
      setDisplayValue(num.toString());
    } else {
      setDisplayValue(displayValue + num.toString());
    } */

    setDisplayValue(displayValue + num.toString());
  };


  const handleOperatorClick = (op) => {
    if (currentValue === null) {
      setCurrentValue(parseFloat(displayValue));
    } else if (operator) {
      applyOperator();
    }
    setOperator(op);
    setDisplayValue('0');
  };



  const applyOperator = () => {
    const value = parseFloat(displayValue);
    switch (operator) {
      case '+':
        setCurrentValue(currentValue + value);
        break;
      case '-':
        setCurrentValue(currentValue - value);
        break;
      case '*':
        setCurrentValue(currentValue * value);
        break;
      case '/':
        setCurrentValue(currentValue / value);
        break;
      default:
        break;
    }
  };


  const handleEqualsClick = () => {
    /* if (operator && currentValue !== null) {
      applyOperator();
      setOperator(null);
      setDisplayValue(currentValue.toString());
      setCurrentValue(null);
     
    } */
    doLogin()
  };


  const handleClearClick = () => {
    /* setDisplayValue('');
    setCurrentValue(null);
    setOperator(null); */
   
    const str = displayValue.substring(0, displayValue.length - 1);
    setDisplayValue(str)
  };

  const doLogin = async ()=>{  // we make this function aysnc because it will return promise

       // console.warn("pin", pin);
       setError(null)
     
     
      if(displayValue){
     
        setLoading(true)
      //  console.log(api_baseurl+"login");return false;
        axios.post(api_baseurl+"login",{
          
          loginPin:displayValue,
          loginType:"web"

      }).then(response=>{
        console.log('aa ',response);
       
        setLoading(false)
       
        const vendor_id = btoa(response?.data?.vendor_id);
        const photo = response?.data?.result?.photo;
        const business_name = response?.data?.result?.business_name;
       

        const status = response.data.status;
        const message = response.data.message;
        const login_type = response.data.login_type;

        if(status=='1'){
         
        //console.log('url encode: ',btoa(vendor_id))
      
          localStorage.clear();
          localStorage.setItem("vendor_id",vendor_id);
          localStorage.setItem("photo",photo);
          localStorage.setItem("business_name",business_name);
          localStorage.setItem("login_type",login_type);
          
          navigate('/dashboard/'+vendor_id)
        }else{
          setErrorValidation(true)
         // console.log('message : ', message);
          setMessage(message)
        }
        
      
      // console.log(localStorage.getItem("vendor_id"),"*****");return false;
       // localStorage.setItem("userInfo");
       
       // setLoading(false)
      }).catch(error=>{

        console.log('bb ',error);
      })

      }else{
       
        setErrorValidation(true);
        setMessage("Please enter pin")
      }
     

  }


   function changeInputType(){

      if(visible==true){
        setVisible(false)
      }
      if(visible==false){
        setVisible(true)
      }
  }
//#955dff

function handleEnterKey(e){

 
    // console.warn("pin", pin);
    setError(null)
  
  if(e.key=='Enter'){
    e.preventDefault();
      doLogin();
    
   
  }
  


}

  return (
    <fregment>
      

      <main id="main" className="main">

    <section className="section">
      <div className="row">       

        <div className="col-lg-6" style={{marginLeft:"100px"}}>
        <h1><img src="assets/images/hubwallet-logo.png" alt="" /></h1>
          {/* <div className="card"> */}
          <div className="">
            <div className="card-body" style={{paddingTop: 0}}>

     
      

             
            {/* <Loader type="box-rotate-x" bgColor={"#4672CF"} title={"box-rotate-x"} color={'#9B2D2D'} size={100} /> */}
            
              <form>
              {/*   <!-- Email input --> */}
                {/* <div className="form-outline mb-4 ">
                <label className="form-label" for="form2Example1" style={{float:"left"}}>Enter PIN</label>
                  

   
                  <div class="input-group">
                  <input type={visible?"text":"password"} id="form2Example1" className="form-control" placeholder="Enter Pin" onChange={(e)=>setPin(e.target.value)} onKeyPress={handleEnterKey} />
                  <div class="input-group-prepend" >
                    <span class="input-group-text" id="basic-addon1" style={{borderRadius:0,cursor:"pointer"}} onClick={changeInputType}><i className={visible?"bi bi-eye":"bi bi-eye-slash"} 
                    id="togglePassword" ></i> </span>
                  </div>
                </div>
     
               



                  {
                    loading?'':
                  
                  <span style={{float:"left",marginBottom:"20px",color:"red"}}>{errorValidation?message:""}</span>
                  }
                </div> 
              
              
                <button type="button" className="form-control btn  btn-block" style={{backgroundColor:"#955dff",color:"#fff"}} onClick={doLogin}>
                  {loading?<span >Loading...</span>:'Log In'}
                  </button>
                */}
              
              </form>



              <div className="calculator">
     


      <div className="input-group display" style={{height:50}}>
        <input type={visible?"text":"password"} id="form2Example1" className="form-control" placeholder="Enter Pin" onChange={(e)=>setDisplayValue(e.target.value)} onKeyPress={handleEnterKey} value={displayValue}/>
        <div class="input-group-prepend" >
          <span class="input-group-text" id="basic-addon1" style={{borderRadius:0,cursor:"pointer",height:50}} onClick={changeInputType}><i className={visible?"bi bi-eye":"bi bi-eye-slash"} 
          id="togglePassword" ></i> </span>
        </div>
      </div>

      <div className="keypad">
        

       

        <button className="btn_button" onClick={() => handleNumberClick('1')}>1</button>
        <button className="btn_button" onClick={() => handleNumberClick('2')}>2</button>
        <button  className="btn_button" onClick={() => handleNumberClick('3')}>3</button>
       

        <button className="btn_button" onClick={() => handleNumberClick('4')}>4</button>
        <button className="btn_button" onClick={() => handleNumberClick('5')}>5</button>
        <button className="btn_button" onClick={() => handleNumberClick('6')}>6</button>
       

        <button className="btn_button" onClick={() => handleNumberClick('7')}>7</button>
        <button className="btn_button" onClick={() => handleNumberClick('8')}>8</button>
        <button className="btn_button" onClick={() => handleNumberClick('9')}>9</button>
       

        
        <button className="btn_button btn_backspace " onClick={handleClearClick}><img src="assets/images/backspace.png" alt="" /></button>
        <button className="btn_button" onClick={() => handleNumberClick('0')}>0</button>
        <button className="btn_login btn_button" onClick={handleEqualsClick}><img src="assets/images/done.png" alt="" /></button>
       
      </div>
      
    </div>
    
    {loading?<span >Loading...</span>:''}
    {
        loading?'':
      
      <span style={{color:"red"}}>{errorValidation?message:""}</span>
    }
            </div>
          </div>

         

        </div>
      </div>

      


      



    </section>

  </main>

     
    </fregment>
  );
}

export default Login;
