import React, { useEffect, useState } from "react";
import {useNavigate, NavLink, useParams, useSearchParams} from 'react-router-dom'
import axios from "axios";
import $ from 'jquery';

const api_baseurl = process.env.REACT_APP_BASE_URL_API;
const base_url = process.env.BASE_URL;



function Header() {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const params = useParams();
  let vendor_id_query_param  = params.vendor_id;
 
 // let vendor_id  = atob(params.vendor_id);



 var login_type = localStorage.getItem("login_type");

  if(login_type=='web' || login_type==null ||  login_type==''){

  var vendor_id = localStorage.getItem("vendor_id");


  if(vendor_id=='null' || vendor_id!=vendor_id_query_param){
    console.log('no vendor id')
    localStorage.clear();
    navigate('/')
  }else{
   // alert(vendor_id)
  } 
 }/* else{
  let vendor_id  = atob(params.vendor_id);
 }  */
 


 
  const [data, setData] = useState("");
  const [queryParameters] = useSearchParams()
  const photo2 = queryParameters.get("photo")
  //const [data, setData] = useState([]);

  
 
  //let vendor_id  = params.vendor_id;

 




/*  let photo =  localStorage.getItem("photo");
 let business_name =  localStorage.getItem("business_name");
 let vendor_id =  localStorage.getItem("vendor_id");
*/


 useEffect(() => {


  /* if(localStorage.getItem("business_name")==null || localStorage.getItem("business_name")=='undefined'){
  
    getBusinessProfile(vendor_id)
  }  */





 /*  if(business_name=='undefined'){
    window.location.reload('/#/'); 

    
  } */
  //console.log('resss: ',data.result);


}, [data.result?.business_name,data.result?.photo]); 



 function getBusinessProfile(vendor_id){

  fetch(api_baseurl+"getBusinessProfile?vendor_id="+vendor_id).then(
    (res) => {
      res.json().then((response) => {
        setData(response);
      
      });
    }
  );

  

  localStorage.setItem("business_name",data.result?.business_name);
  localStorage.setItem("photo",data.result?.photo);
  localStorage.setItem("vendor_id",vendor_id);


 }


 var business_name = localStorage.getItem("business_name");
  var photo = localStorage.getItem("photo");

  


 /*  function logout(){
   
      localStorage.clear();
      navigate('/')
     // window.location.reload('/#/');
    


  } */

  const logout = () =>{
    setLoading(true)
    axios.post(api_baseurl+"logout",{
          
      vendor_id:vendor_id
      
     
  }).then(response=>{
    
   // console.log('aa ',response);
   
    
   
    const status = response.data.status;


    if(status=='1'){
      setLoading(false)
      localStorage.clear();
     // navigate('/')
    /*  const urlAddress = "https://www.google.co.in/";
     window.location.href = "ios::openSafari::" + urlAddress; */
   //  alert(base_url_1)
    navigate('/')
   // window.location.href = '/debug'
     

    }else{
     // setErrorValidation(true)
    
    }

  }).catch(error=>{

    console.log('bb ',error);
  })

  }

  function handleDrawer(){

   document.body.classList.toggle('toggle-sidebar');
 
    
  }
  
/* <Navigate replace to="/login" />; */
  return (
    <fregment>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <a href="javascript:;" className="logo d-flex align-items-center">
            <img src={photo} alt="" /> <span className="d-none d-lg-block">
              {business_name}
              </span>
            {/* <span className="d-none d-lg-block">ReportAPP</span> */}
           
          </a>
        </div>

        <i className="bi bi-list toggle-sidebar-btn" onClick={handleDrawer}></i>
       {/*  <NavLink style={{textAlign:"right"}} onClick={logout}>Logout</NavLink> */}

        <nav className="header-nav ms-auto">
      <ul className="d-flex align-items-center">

        {/* <li className="nav-item d-block d-lg-none">
          <a className="nav-link nav-icon search-bar-toggle " href="#">
            <i className="bi bi-search"></i>
          </a>
        </li> */}

        

        <li className="nav-item dropdown pe-3">
          <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
            <img src={photo} alt="Profile" className="rounded-circle"/>
            <span className="d-none d-md-block dropdown-toggle ps-2" style={{color:"#fff"}}>{business_name}</span>
          </a>

          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
            <li className="dropdown-header">
              <h6>{business_name}</h6>
              <span>Admin</span>
            </li>
            <li>
              <hr className="dropdown-divider"/>
            </li>

           {/*  <li>
              <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                <i className="bi bi-person"></i>
                <span>My Profile</span>
              </a>
            </li>
            <li>
              <hr className="dropdown-divider"/>
            </li> */}

           

            <li>
              <a className="dropdown-item d-flex align-items-center" href="javascript:;" onClick={logout}>
                <i className="bi bi-box-arrow-right"></i>
                <span>Sign Out</span>
              </a>
            </li>

          </ul>
        </li>

      </ul>
    </nav>

    {/*  <NavLink style={{textAlign:"right"}} onClick={logout}>Logout</NavLink> */}
      </header>
    </fregment>
  );
}

export default Header;
