import logo from './logo.svg';
import './App.css';
import {BrowserRouter, HashRouter, Routes, Route, Link} from 'react-router-dom'
import TransactionList from './pages/TransactionList';
import TransactionDetail from './pages/TransactionDetail';
import TransactionDetailRefund from './pages/TransactionDetailRefund';
import Discount from './pages/Discount';
import Void from './pages/Void';
import CreditCertificate from './pages/CreditCertificate';
import Egift from './pages/Egift';
import Services from './pages/Services';
import Products from './pages/Products';
import Page404 from './pages/Page404';
import CurrentStock from './pages/CurrentStock';
import LowOrderQty from './pages/LowOrderQty';
import StockAnalysis from './pages/StockAnalysis';
import ProfitMargin from './pages/ProfitMargin';
import PurchaseHistory from './pages/PurchaseHistory';
import AppointmentSummary from './pages/AppointmentSummary';
import BookingMethod from './pages/BookingMethod';
import ActiveEmployeeList from './pages/ActiveEmployeeList';
import EmployeeProfile from './pages/EmployeeProfile';
import PayrateAndCommission from './pages/PayrateAndCommission';
import CommissionSummary from './pages/CommissionSummary';
import AttendanceDetail from './pages/AttendanceDetail';
import HoursAndWagesSummary from './pages/HoursAndWagesSummary';
import EmployeeSalesSummary from './pages/EmployeeSalesSummary';
import CustomerList from './pages/CustomerList';
import CustomerAppointment from './pages/CustomerAppointment';
import CustomerSales from './pages/CustomerSales';
import CustomerEgiftCard from './pages/CustomerEgiftCard';
import CustomerCreditCertificate from './pages/CustomerCreditCertificate';
import Login from './pages/Login';
import SalesAndPaymentSummary from './pages/SalesAndPaymentSummary';
import Dashboard from './pages/Dashboard';
import GeneralProgram from './pages/GeneralProgram';
import EventBased from './pages/EventBased';
import Refund from './pages/Refund';

//import DateRangePickerComp from './pages/DateRangePickerComp';


function App() {
  return (
    <div className="App">
      <HashRouter >
        <Routes>

          <Route path="/transaction-list/:vendor_id" element={<TransactionList/>} />
          <Route path="/transaction-detail/:vendor_id/:order_id" element={<TransactionDetail/>} />
          <Route path="/transaction-detail-refund/:vendor_id/:order_id" element={<TransactionDetailRefund/>} />
          <Route path="/discount/:vendor_id" element={<Discount/>} />
          <Route path="/void/:vendor_id" element={<Void/>} />
          <Route path="/refund/:vendor_id" element={<Refund/>} />
          <Route path="/credit-certificate/:vendor_id" element={<CreditCertificate/>} />
          <Route path="/egift/:vendor_id" element={<Egift/>} />
          <Route path="/services/:vendor_id" element={<Services/>} />
          <Route path="/products/:vendor_id" element={<Products/>} />
          <Route path="/current-stock/:vendor_id" element={<CurrentStock/>} />
          <Route path="/low-order-qty/:vendor_id" element={<LowOrderQty/>} />
          <Route path="/stock-analysis/:vendor_id" element={<StockAnalysis/>} />
          <Route path="/profit-margin/:vendor_id" element={<ProfitMargin/>} />
          <Route path="/purchase-history/:vendor_id" element={<PurchaseHistory/>} />
          <Route path="/appointment-summary/:vendor_id" element={<AppointmentSummary/>} />
          <Route path="/booking-method/:vendor_id" element={<BookingMethod/>} />
          <Route path="/active-employee-list/:vendor_id" element={<ActiveEmployeeList/>} />
          <Route path="/employee-profile/:vendor_id" element={<EmployeeProfile/>} />
          <Route path="/payrate-and-commission/:vendor_id" element={<PayrateAndCommission/>} />
          <Route path="/commission-summary/:vendor_id" element={<CommissionSummary/>} />
          <Route path="/attendance-detail/:vendor_id" element={<AttendanceDetail/>} />
          <Route path="/hours-and-wages-summary/:vendor_id" element={<HoursAndWagesSummary/>} />
          <Route path="/employee-sales-summary/:vendor_id" element={<EmployeeSalesSummary/>} />
          <Route path="/customer-list/:vendor_id" element={<CustomerList/>} />
          <Route path="/customer-appointment/:vendor_id" element={<CustomerAppointment/>} />
          <Route path="/customer-sales/:vendor_id" element={<CustomerSales/>} />
          <Route path="/customer-egift-card/:vendor_id" element={<CustomerEgiftCard/>} />
          <Route path="/customer-credit-certificate/:vendor_id" element={<CustomerCreditCertificate/>} />
          <Route path="/sales-and-payment-summary/:vendor_id" element={<SalesAndPaymentSummary/>} />
          <Route path="/general-program/:vendor_id" element={<GeneralProgram/>} />
          <Route path="/event-based/:vendor_id" element={<EventBased/>} />
          <Route path="/dashboard/:vendor_id" element={<Dashboard/>} />
          <Route path="/" element={<Login/>} />
          
          {/* <Route path="/daterange" element={<DateRangePickerComp/>} /> */}
          <Route path="*" element={<Page404/>} />
          
          
        </Routes>
      </HashRouter>
    
    </div>
  );
}

export default App;
