import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel,useDownloadExcel } from 'react-export-table-to-excel';
import { useParams, NavLink, useNavigate} from "react-router-dom";
import Loader from "./include/Loader";
import { DatePicker, Space } from 'antd';
import $ from 'jquery';
import { DateRangePicker } from 'rsuite';





const api_baseurl = process.env.REACT_APP_BASE_URL_API



function TransactionList() {
 

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  //const [sorting, setSorting] = useState({ field: 'date', ascending: false })
  const [order, setOrder] = useState("ASC");
  const [sheetData, setSheetData] = useState(null);

  const navigate = useNavigate()

  const params = useParams();
  //////let vendor_id_query_param  = atob(params.vendor_id);
  let vendor_id  = atob(params.vendor_id);

  ///////var vendor_id = atob(localStorage.getItem("vendor_id"));
 //console.log('query param: ',vendor_id_query_param,vendor_id)
   /*  if(vendor_id=='null' || vendor_id!=vendor_id_query_param){
      console.log('no vendor id')
      localStorage.clear();
      navigate('/')
    } */

  const tableRef = useRef(null);

 
  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.result.sort((a,b) =>
      
         col=='transaction_amount'
         ?
         a[col].toLowerCase()-b[col].toLowerCase()
         :
         a[col].toLowerCase() > b[col].toLowerCase()? 1: -1
        
      );
      
     // setData(sorted)
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.result.sort((a,b)=>
          col=='transaction_amount'
          ?
          b[col].toLowerCase() - a[col].toLowerCase()
          :
          a[col].toLowerCase() < b[col].toLowerCase()? 1: -1
       
      );
    // setData(sorted)
      setOrder("ASC")
    }
  }

  function parentAlert(demoData){
  
    setDateRange(demoData)
    setFinalDateValue(searchDateRange)
  }

  //setFinalDateValue(searchDateRange)

  function getCalendarValue(){

    setFinalDateValue(searchDateRange)

    console.log('calendar value:  ',finalDateValue)

  
    loadData(finalDateValue)

  }
 

 
//let test = "2022-12-01 to 2022-12-13";

function loadData(datefilter){
 
 /*  let split_date = datefilter.split(' to ')
  let start_date = split_date[0];
  let end_date = split_date[1]; */

  setLoading(true)
  fetch(api_baseurl+"transactionList?vendor_id="+vendor_id+"&date_range="+datefilter).then(
    (res) => {
      res.json().then((response) => {
        setData(response);
       
        setLoading(false)
      });
    }
  );
}



  
  useEffect(() => {
   // console.log('chess ',finalDateValue)
    getCalendarValue()
    loadData(finalDateValue)
    setSheetData(data)

  }, []); 


  
  
//console.log('data : ',data)
  //data.result.filter(item=>item.status.toLowerCase().includes(searchQuery))


/*   const searchQ = (data)=>{
    return data.result.filter(item=>item.status.toLowerCase().includes(searchQuery));
  } 

  console.log('sss ',searchQ); */

  /* function searchQ(data){
    return data.result.filter(item=>item.customer_name.toLowerCase().includes(searchQuery));
  } */

 


  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>
      
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Transaction List</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Sales Reports</a>
              </li>
              <li className="breadcrumb-item active">Transaction List</li>
            </ol>
            {/* <DownloadTableExcel filename="transaction" sheet="transaction"  currentTableRef={tableRef.current}>
      <button className="export-data"><i className="icon-excel"></i> Export Data</button>
      </DownloadTableExcel> */}

    
      <a href={api_baseurl+"transactionList?vendor_id="+vendor_id+"&date_range="+finalDateValue+"&is_download=1"}> <button className="export-data" ><i className="icon-excel"></i> Export Data</button></a>
{/* 
<button className="export-data" onClick={downloadExcelData} ><i className="icon-excel"></i> Export Data</button> */}
    {/*   <a href="http://174.138.40.44/salon/report_app/reports/downloadExcelData?vendor_id="><button className="export-data" ><i className="icon-excel"></i> Export Data</button></a>
 */}
{/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="export-data"
                    table="transaction_list_table"
                    filename="Transaction"
                    sheet="Transaction"
                    buttonText="Export Data"/> */}

      
          </nav>
        </div>
        
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic">
                          <label for="selectdate"></label>
                         
                         <DateRangePickerComp alert={parentAlert}></DateRangePickerComp>  
                          
                        
                         {/* <Space direction="vertical" size={12}>
    <DatePicker renderExtraFooter={() => 'extra footer'} />
    <DatePicker renderExtraFooter={() => 'extra footer'} showTime />
    <RangePicker renderExtraFooter={() => 'extra footer'} />
     <RangePicker renderExtraFooter={() => 'extra footer'} showTime />
    <DatePicker renderExtraFooter={() => 'extra footer'} picker="month" /> 
  </Space>  */}

                          {/* <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-calendar-ic"></i>
                            </button>
                          </div> */}
                          <button type="button" className="go-btn" onClick={getCalendarValue}>Go</button>
                        </div>
                        <div className="col search-ic">
                          <label for="selectdate"></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e)=>setQuery(e.target.value)}
                          />
                          <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>


                  



                  {loading?<Loader></Loader>:
                  <div className="table-responsive orderid-select">
                    <table className="table table-striped table-bordered" id="transaction_list_table" ref={tableRef}>
                      <thead>
                        <tr>
                          <th scope="col"  >
                          <a
                              className="blk dataTable-sorter"
                              href="javascript:void(0);"
                              onClick={()=>sorting("date")}
                            >
                              Date
                           </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk dataTable-sorter"
                              href="javascript:void(0);"
                              onClick={()=>sorting("time")}
                            >
                              Time
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk dataTable-sorter"
                              href="javascript:void(0);"
                              onClick={()=>sorting("order_number")}
                            >
                              Order
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk dataTable-sorter"
                              href="javascript:void(0);"
                              onClick={()=>sorting("status")}
                            >
                              Status
                            </a>
                          </th>
                          <th scope="col">
                          <a
                              className="blk dataTable-sorter"
                              href="javascript:void(0);"
                              onClick={()=>sorting("customer_name")}
                            >
                              Customer
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              className="blk dataTable-sorter"
                              href="javascript:void(0);"
                              onClick={()=>sorting("transaction_amount")}
                            >
                              Amount
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.result &&
                          data.result.filter(item=>
                            item.date.includes(searchQuery.toLowerCase()) ||
                            item.time.includes(searchQuery.toLowerCase()) ||
                            item.time.includes(searchQuery) ||
                            item.order_number.includes(searchQuery.toLowerCase()) ||
                           /*  item.status.includes(searchQuery.toLowerCase()) || */
                            item.status.toLowerCase().includes(searchQuery) ||
                            item.status.toUpperCase().includes(searchQuery) ||
                            item.status.includes(searchQuery) ||
                            item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            item.transaction_amount.includes(searchQuery.toLowerCase()) 

                          

                             )
                            .map((item, index) => (
                            <tr id={index} key={index}>
                              <td>{item.date}</td>
                              <td>{item.time}</td>
                              <td>
                                {item.status=='Complete'?
                                <NavLink to={"/transaction-detail/"+btoa(vendor_id)+"/"+btoa(item.order_id)}>{item.order_number}</NavLink>
                                 :
                                 <NavLink to={"/transaction-detail-refund/"+btoa(vendor_id)+"/"+btoa(item.order_id)}>{item.order_number}</NavLink>
                                 }
                              </td>
                              <td>{item.status}</td>
                              <td>{item.customer_name}</td>
                              <td style={{textAlign:"right"}}>${parseFloat(item.transaction_amount).toFixed(2)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                    }
                  {/* <Pagination></Pagination> */}
                </div>
               
              </div>
              
            </div>
            
          </div>
          
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default TransactionList;
