import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams } from "react-router-dom";
import Loader from "./include/Loader";

const api_baseurl = process.env.REACT_APP_BASE_URL_API

function HoursAndWagesSummary() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  const [order, setOrder] = useState("ASC");

  const tableRef = useRef(null);

  const params = useParams();
  let vendor_id  = atob(params.vendor_id);

  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.result.sort((a,b) =>
      
         col=='commission' || col=='card_tip' || col=='cash_tip'
         ?
         a[col].toLowerCase()-b[col].toLowerCase()
         :
         a[col].toLowerCase() > b[col].toLowerCase()? 1: -1
        
      );
      
     // setData(sorted)
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.result.sort((a,b)=>
      col=='commission' || col=='card_tip' || col=='cash_tip'
          ?
          b[col].toLowerCase() - a[col].toLowerCase()
          :
          a[col].toLowerCase() < b[col].toLowerCase()? 1: -1
       
      );
    // setData(sorted)
      setOrder("ASC")
    }
  }
  

  function parentAlert(demoData) {
  
    setDateRange(demoData);
    setFinalDateValue(searchDateRange);
  }

  useEffect(() => {
    getCalendarValue();
    loadData(finalDateValue);
  }, []);

  function loadData(datefilter) {
  
    setLoading(true);
    fetch(
      api_baseurl+"hours_and_wages?vendor_id=" +
        vendor_id +
        "&date_range=" +
        datefilter
    ).then((res) => {
      res.json().then((response) => {
        setData(response);
        setLoading(false);
      });
    });
  }

  function getCalendarValue() {
    setFinalDateValue(searchDateRange);
    loadData(finalDateValue);
  }

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Hours & Wages Summary</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Employee Reports</a>
              </li>
              <li className="breadcrumb-item active">Hours & Wages Summary</li>
            </ol>
            <a href={api_baseurl+"hours_and_wages?vendor_id="+vendor_id+"&date_range="+finalDateValue+"&is_download=1"}> <button className="export-data" ><i className="icon-excel"></i> Export Data</button></a>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic">
                          <label for="selectdate"></label>
                          <DateRangePickerComp
                            alert={parentAlert}
                          ></DateRangePickerComp>
                          {/* <div className="calendar-ic">
                      <button type="button" onClick={getCalendarValue}><i className="icon-calendar-ic"></i></button>
                      </div> */}
                          <button
                            type="button"
                            className="go-btn"
                            onClick={getCalendarValue}
                          >
                            Go
                          </button>
                        </div>
                        <div className="col search-ic">
                          <label for="selectdate"></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  {/*  <!-- Table with stripped rows --> */}
                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <div className="table-responsive orderid-select">
                      <table
                        class="table table-striped table-bordered"
                        rel="tableRef"
                      >
                        <thead>
                          <tr>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("firstname")}
                              >
                                First Name
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("lastname")}
                              >
                                Last Name
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("total_hours")}
                              >
                                Total Hours
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("commission")}
                              >
                                Commission
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("card_tip")}
                              >
                                Card Tips
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                class="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("cash_tip")}
                              >
                                Cash Tips
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.result &&
                            data.result
                              .filter(
                                (item) =>
                                  item.firstname
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.lastname
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.total_hours
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.commission
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.card_tip
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.cash_tip
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((item) => (
                                <tr id={item.stylist_id} key={item.stylist_id}>
                                  <td>{item.firstname}</td>
                                  <td>{item.lastname}</td>
                                  <td>{item.total_hours}</td>
                                  <td style={{ textAlign: "right" }}>
                                    ${parseFloat(item.commission).toFixed(2)}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    ${parseFloat(item.card_tip).toFixed(2)}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    ${parseFloat(item.cash_tip).toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* <!-- End Table with stripped rows --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </fregment>
  );
}

export default HoursAndWagesSummary;
