import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import Pagination from "./include/Pagination";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams, NavLink } from "react-router-dom";
import Loader from "./include/Loader";

const api_baseurl = process.env.REACT_APP_BASE_URL_API

function Void() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");
  const tableRef = useRef(null);
  const [order, setOrder] = useState("ASC");

  const params = useParams();
  let vendor_id  = atob(params.vendor_id);

  function parentAlert(demoData) {
    setDateRange(demoData);
    setFinalDateValue(searchDateRange);
  }

  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.result.sort((a,b) =>
      
         col=='discount_amount'
         ?
         a[col].toLowerCase()-b[col].toLowerCase()
         :
         a[col].toLowerCase() > b[col].toLowerCase()? 1: -1
        
      );
      
     // setData(sorted)
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.result.sort((a,b)=>
          col=='discount_amount'
          ?
          b[col].toLowerCase() - a[col].toLowerCase()
          :
          a[col].toLowerCase() < b[col].toLowerCase()? 1: -1
       
      );
    // setData(sorted)
      setOrder("ASC")
    }
  }

  useEffect(() => {
    getCalendarValue();
    loadData(finalDateValue);
  }, []);

  function loadData(datefilter) {
    setLoading(true);
    fetch(
      api_baseurl+"voids?vendor_id=" +
        vendor_id +
        "&date_range=" +
        datefilter
    ).then((res) => {
      res.json().then((response) => {
        setData(response);
        setLoading(false);
      });
    });
  }

  function getCalendarValue() {
    setFinalDateValue(searchDateRange);
    loadData(finalDateValue);
  }

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Voids</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Sales Reports</a>
              </li>
              <li className="breadcrumb-item active">Voids</li>
            </ol>
            <DownloadTableExcel
              filename="Voids"
              sheet="Voids"
              currentTableRef={tableRef.current}
            >
              <button className="export-data">
                <i className="icon-excel"></i> Export Data
              </button>
            </DownloadTableExcel>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic">
                          <label for="selectdate"></label>
                          <DateRangePickerComp
                            alert={parentAlert}
                          ></DateRangePickerComp>
                          {/* <div className="calendar-ic">
                    <button type="button" onClick={getCalendarValue}><i className="icon-calendar-ic"></i></button>
                  </div> */}
                          <button
                            type="button"
                            className="go-btn"
                            onClick={getCalendarValue}
                          >
                            Go
                          </button>
                        </div>
                        <div className="col search-ic">
                          <label for="selectdate"></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                 
                    <section className="section pst-boxes">
                      <div className="row">
                        <div className="container text-center">
                          <div className="row row-cols-1   row-cols-lg-4 g-3 g-lg-3">
                            <div className="col">
                              <div className="cmn-box">
                                <span>Products</span>
                                <h5>$0.00</h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>Services</span>
                                <h5>$0.00</h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>Order Discount</span>
                                <h5>
                                  $0.00
                                </h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="cmn-box">
                                <span>Total</span>
                                <h5>$0.00</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                
                 
                    <div className="table-responsive orderid-select">
                      <table
                        className="table table-striped table-bordered"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={() => sorting("order_date")}
                              >
                                Date
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("order_time")}
                              >
                                Time
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("order_number")}
                              >
                                Order
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("checkout_by")}
                              >
                                Checkout By
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("service_provider")}
                              >
                                Service Provider
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("customer_name")}
                              >
                                Customer
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("type")}
                              >
                                Service/Product
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("discount_amount")}
                              >
                                Amount
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                         
                                <tr id="">
                                  <td></td>
                                  <td></td>
                                  <td>
                                    
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td style={{ textAlign: "right" }}>
                                    
                                  </td>
                                </tr>
                             
                        </tbody>
                      </table>
                    </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </fregment>
  );
}

export default Void;
