import React, { useEffect, useState, useRef } from "react";
import Footer from "./include/Footer";
import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import DateRangePickerComp from "./DateRangePickerComp";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useParams, NavLink } from "react-router-dom";
import Loader from "./include/Loader";

const api_baseurl = process.env.REACT_APP_BASE_URL_API

function CreditCertificate() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setQuery] = useState("");
  const [order, setOrder] = useState("ASC");
  const [searchDateRange, setDateRange] = useState("");
  const [finalDateValue, setFinalDateValue] = useState("");

  const tableRef = useRef(null);

  const params = useParams();
  let vendor_id  = atob(params.vendor_id);

  //const [searchDateRange, setDateRange] = useState("");

  const sorting = (col)=>{

    if(order === 'ASC'){
      const sorted = data.result.sort((a,b) =>
      
         col=='amount'
         ?
         a[col].toLowerCase()-b[col].toLowerCase()
         :
         a[col].toLowerCase() > b[col].toLowerCase()? 1: -1
        
      );
      
     // setData(sorted)
      setOrder("DSC")
    }

    if(order==='DSC'){
      const sorted = data.result.sort((a,b)=>
          col=='amount'
          ?
          b[col].toLowerCase() - a[col].toLowerCase()
          :
          a[col].toLowerCase() < b[col].toLowerCase()? 1: -1
       
      );
    // setData(sorted)
      setOrder("ASC")
    }
  }

  function parentAlert(demoData) {
    setDateRange(demoData);
    setFinalDateValue(searchDateRange);
  }

  useEffect(() => {
    getCalendarValue();
    loadData(finalDateValue);
  }, []);

  function loadData(datefilter) {
    setLoading(true);
    fetch(
      api_baseurl+"creditCertificate?vendor_id=" +
        vendor_id +
        "&date_range=" +
        datefilter
    ).then((res) => {
      res.json().then((response) => {
        setData(response);
        setLoading(false);
      });
    });
  }

  function getCalendarValue() {
    setFinalDateValue(searchDateRange);
    loadData(finalDateValue);
  }

  /*   const sorting = (col)=>{

    if(sortOrder=='ASC'){
      const sorted = [...data.result].sort((a,b)=>
        a[col].toLowerCase()>b[col].toLowerCase()?1:-1
      );
      setData(sorted);
      setOrder("DSC");
    }

    if(sortOrder=='DSC'){
      const sorted = [...data.result].sort((a,b)=>
        a[col].toLowerCase()<b[col].toLowerCase()?1:-1
      );
      setData(sorted);
      setOrder("ASC");
    }

  } */

  return (
    <fregment>
      <Header></Header>
      <Sidebar></Sidebar>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Certificate</h1>
          <div className="seprator"></div>
          <nav className="export-relative">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:;">Sales Reports</a>
              </li>
              <li className="breadcrumb-item active">Certificate</li>
            </ol>
            <a href={api_baseurl+"creditCertificate?vendor_id="+vendor_id+"&date_range="+finalDateValue+"&is_download=1"}> <button className="export-data" ><i className="icon-excel"></i> Export Data</button></a>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="top-form">
                    <form>
                      <div className="row">
                        <div className="col date-ic">
                          <label for="selectdate"></label>
                          <DateRangePickerComp
                            alert={parentAlert}
                          ></DateRangePickerComp>
                          {/* <div className="calendar-ic">
                          <button type="button" onClick={getCalendarValue}><i className="icon-calendar-ic"></i></button>
                          </div> */}
                          <button
                            type="button"
                            className="go-btn"
                            onClick={getCalendarValue}
                          >
                            Go
                          </button>
                        </div>
                        <div className="col search-ic">
                          <label for="selectdate"></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="calendar-ic">
                            <button type="button">
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {loading ? (
                    ""
                  ) : (
                    <section className="section pst-boxes">
                      <div className="row">
                        <div className="col-lg-12">
                          <ul>
                            <li>
                              <span>Total Issued</span>
                              <h5>
                                $
                                {data.total_issued ? data.total_issued : "0.00"}
                              </h5>
                            </li>

                            <li>
                              <span>Total Redeemed</span>
                              <h5>
                                $
                                {data.total_redeemed
                                  ? data.total_redeemed
                                  : "0.00"}
                              </h5>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>
                  )}
                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <div className="table-responsive orderid-select">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th
                              scope="col" /* onClick={()=>sorting("order_date")} */
                            >
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("order_date")}
                              >
                                Issue Date
                              </a>
                            </th>
                            <th
                              scope="col" /* onClick={()=>sorting("order_time")} */
                            >
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("order_time")}
                              >
                                Time
                              </a>
                            </th>
                            <th
                              scope="col" /* onClick={()=>sorting("order_number")} */
                            >
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("order_number")}
                              >
                                Order
                              </a>
                            </th>
                            <th
                              scope="col" /* onClick={()=>sorting("gift_certificate_no")} */
                            >
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("gift_certificate_no")}
                              >
                                Certificate Number
                              </a>
                            </th>
                            <th
                              scope="col" /* onClick={()=>sorting("customer_name")} */
                            >
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("customer_name")}
                              >
                                Customer
                              </a>
                            </th>
                            {/* <th scope="col" >
                            <a
                              className="blk dataTable-sorter"
                              href="javascript:void(0);"
                            >
                              Service Provider
                            </a>
                          </th> */}
                            <th
                              scope="col" /* onClick={()=>sorting("expiry_date")} */
                            >
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("expiry_date")}
                              >
                                Exp. Date
                              </a>
                            </th>
                            <th
                              scope="col" /* onClick={()=>sorting("redeemed_date")} */
                            >
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("redeemed_date")}
                              >
                                Redeemed Date
                              </a>
                            </th>
                            <th
                              scope="col" /* onClick={()=>sorting("service_name")} */
                            >
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("service_name")}
                              >
                                Service
                              </a>
                            </th>
                            <th
                              scope="col" /* onClick={()=>sorting("amount")} */
                            >
                              <a
                                className="blk dataTable-sorter"
                                href="javascript:void(0);"
                                onClick={()=>sorting("amount")}
                              >
                                Amount
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.result &&
                            data.result
                              .filter((item) => {
                                if (searchQuery == "") {
                                  return item;
                                } else if (
                                  item.order_date
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.order_time
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.order_number
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.gift_certificate_no
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.customer_name
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  /* item.service_provider?.toLowerCase().includes(searchQuery.toLowerCase()) || */
                                  item.service_name
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.amount
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.expiry_date
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                ) {
                                  return item;
                                }
                              })
                              .map((item,index) => (
                                <tr id={index} key={index}>
                                  <td>{item.order_date}</td>
                                  <td>{item.order_time}</td>
                                  <td>
                                    <NavLink
                                      to={
                                        "/transaction-detail/" +
                                        btoa(vendor_id) +
                                        "/" +
                                        btoa(item.order_id)
                                      }
                                    >
                                      {item.order_number}
                                    </NavLink>
                                  </td>
                                  <td>{item.gift_certificate_no}</td>
                                  <td>{item.customer_name}</td>
                                  {/*  <td>{item.service_provider}</td> */}
                                  <td>{item.expiry_date}</td>
                                  <td>{item.redeemed_date}</td>
                                  <td>{item.service_name}</td>
                                  <td style={{ textAlign: "right" }}>
                                    ${item.amount}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </fregment>
  );
}

export default CreditCertificate;
